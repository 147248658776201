<div class="list-item">
    <div class="newrow">
        <strong>Platinum</strong>
        <input class="number4" type="number" [(ngModel)]="cash.platinum" (keypress)="positiveNumbersOnly($event)">
        <strong>Gold</strong>
        <input class="number4" type="number" [(ngModel)]="cash.gold" (keypress)="positiveNumbersOnly($event)">
        <strong>Silver</strong>
        <input class="number4" type="number" [(ngModel)]="cash.silver" (keypress)="positiveNumbersOnly($event)">
        <strong>Copper</strong>
        <input class="number4 center-aligned" type="number" [(ngModel)]="cash.copper"
            (keypress)="positiveNumbersOnly($event)">
    </div>
    <div class="newrow">
        <span>
            <button (click)="addCash(1)" [disabled]="isCashInvalid()">Gain</button>
        </span>
        <span>
            <button (click)="addCash(-1)" [disabled]="isCashInvalid()" [disabled]="!hasFunds()">Spend</button>
        </span>
    </div>
</div>
