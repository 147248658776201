<!-- eslint-disable @angular-eslint/template/cyclomatic-complexity -->
<button class="minimizebutton lower"
    [ngbTooltip]="isMinimized ? 'Click to show all information.' : 'Click to show compact information.'"
    [ngClass]="{'fancy-button':isMinimized}" (click)="minimize()">
    <i class='bi-arrows-collapse' *ngIf="isMinimized"></i>
    <i class='bi-arrows-expand' *ngIf="!isMinimized"></i>
</button>
<div id="animal-companion-height" class="itembox vlist">
    <button class="itembox-close-button list-item center-aligned" (click)="toggleCompanionMenu()">
        <header class="sectionHeader">Back to Character Sheet</header>
    </button>
    <div class="loading" *ngIf="stillLoading">Loading</div>
    <div class="animalcompanion-box"
        *ngIf="!stillLoading && character.class && isCompanionAvailable && companionMenuState === 'in'">
        <!--Desktop-only view-->
        <ng-container *ngIf="!isMobile">
            <app-effects [creature]="creatureTypesEnum.AnimalCompanion" id="Companion-effects"
                class="charactersheet-app charactersheet-row mobile-hide">
            </app-effects>
            <div class="charactersheet-column-container mobile-hide">
                <div class="charactersheet-column">
                    <app-general [creature]="creatureTypesEnum.AnimalCompanion" id="Companion-general"
                        class="charactersheet-app"></app-general>
                    <app-health [creature]="creatureTypesEnum.AnimalCompanion" id="Companion-health"
                        class="charactersheet-app"></app-health>
                    <app-skills [creature]="creatureTypesEnum.AnimalCompanion" id="Companion-skills"
                        class="charactersheet-app"></app-skills>
                    <app-activities [creature]="creatureTypesEnum.AnimalCompanion" id="Companion-activities"
                        class="charactersheet-app"></app-activities>
                </div>
                <div class="charactersheet-column">
                    <app-abilities [creature]="creatureTypesEnum.AnimalCompanion" id="Companion-abilities"
                        class="charactersheet-app"></app-abilities>
                    <app-defense [creature]="creatureTypesEnum.AnimalCompanion" id="Companion-defense"
                        class="charactersheet-app"></app-defense>
                    <app-attacks [creature]="creatureTypesEnum.AnimalCompanion" id="Companion-attacks"
                        class="charactersheet-app"></app-attacks>
                    <app-inventory [creature]="creatureTypesEnum.AnimalCompanion" id="Companion-inventory"
                        class="charactersheet-app"></app-inventory>
                </div>
            </div>
        </ng-container>
        <!--Mobile-only buttons and views-->
        <ng-container *ngIf="isMobile">
            <div class="charactersheet-app charactersheet-row desktop-hide" id="mobile-tabs">
                <button [ngClass]="{'fancy-button':showMode()===''}" (click)="toggleShowMode('')">
                    <i class="bi-info-circle-fill"></i>
                </button>
                <button [ngClass]="{'fancy-button':showMode()==='Health'}" (click)="toggleShowMode('Health')">
                    <i class="ra ra-health"></i>
                </button>
                <button [ngClass]="{'fancy-button':showMode()==='Defense'}" (click)="toggleShowMode('Defense')">
                    <i class="ra ra-shield"></i>
                </button>
                <button [ngClass]="{'fancy-button':showMode()==='Attacks'}" (click)="toggleShowMode('Attacks')">
                    <i class="ra ra-sword"></i>
                </button>
                <button [ngClass]="{'fancy-button':showMode()==='Skills'}" (click)="toggleShowMode('Skills')">
                    <i class="ra ra-muscle-up"></i>
                </button>
                <button [ngClass]="{'fancy-button':showMode()==='Actions'}" (click)="toggleShowMode('Actions')">
                    <app-actionIcons [actionString]="'1A'" style="margin:.12rem -.16rem -.14rem -.16rem">
                    </app-actionIcons>
                </button>
                <button [ngClass]="{'fancy-button':showMode()==='Inventory'}" (click)="toggleShowMode('Inventory')">
                    <i class="bi-box"></i>
                </button>
                <button [ngClass]="{'fancy-button':showMode()==='ConditionsEffects'}"
                    (click)="toggleShowMode('ConditionsEffects')">
                    <i class="ra ra-lightning-bolt"></i>
                </button>
            </div>
            <ng-container *ngIf="!stillLoading">
                <div class="charactersheet-column-container desktop-hide">
                    <div class="charactersheet-column">
                        <app-general id="Character-general" class="charactersheet-app"
                            [creature]="creatureTypesEnum.AnimalCompanion" [ngClass]="{'minimized':(isMinimized)}"
                            *ngIf="!showMode() || !['Health','Defense','Attacks','Spells','Skills','Actions','Inventory','ConditionsEffects'].includes(showMode())">
                        </app-general>
                        <app-abilities id="Character-abilities" class="charactersheet-app"
                            [creature]="creatureTypesEnum.AnimalCompanion" [ngClass]="{'minimized':(isMinimized)}"
                            *ngIf="!showMode()">
                        </app-abilities>
                        <app-health id="Character-health" class="charactersheet-app"
                            [creature]="creatureTypesEnum.AnimalCompanion"
                            [showMinimizeButton]="(showMode() !== 'Downtime')"
                            [ngClass]="{'minimized':(isMinimized || (showMode() === 'Downtime'))}"
                            *ngIf="showMode()==='Health'">
                        </app-health>
                        <app-defense id="Character-defense" class="charactersheet-app"
                            [creature]="creatureTypesEnum.AnimalCompanion" [ngClass]="{'minimized':(isMinimized)}"
                            *ngIf="showMode()==='Defense'">
                        </app-defense>
                        <app-attacks id="Character-attacks" class="charactersheet-app"
                            [creature]="creatureTypesEnum.AnimalCompanion" [ngClass]="{'minimized':(isMinimized)}"
                            *ngIf="showMode()==='Attacks'">
                        </app-attacks>
                        <app-skills id="Character-skills" class="charactersheet-app"
                            [creature]="creatureTypesEnum.AnimalCompanion" [ngClass]="{'minimized':(isMinimized)}"
                            *ngIf="showMode()==='Skills'">
                        </app-skills>
                        <app-activities id="Character-activities" class="charactersheet-app"
                            [creature]="creatureTypesEnum.AnimalCompanion" [ngClass]="{'minimized':(isMinimized)}"
                            *ngIf="showMode()==='Actions'">
                        </app-activities>
                        <app-inventory id="Character-inventory" class="charactersheet-app"
                            [creature]="creatureTypesEnum.AnimalCompanion" [ngClass]="{'minimized':(isMinimized)}"
                            *ngIf="showMode()==='Inventory'">
                        </app-inventory>
                        <app-effects id="Character-effects" class="charactersheet-app"
                            [creature]="creatureTypesEnum.AnimalCompanion" [fullDisplay]="true"
                            *ngIf="showMode()==='ConditionsEffects'">
                        </app-effects>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
</div>
