<!--Adding Oils-->
<ng-container *ngIf="availableOils() as availableOils">
    <div class="list-item" *ngIf="availableOils.length > 1 || item.oilsApplied.length">
        <div class="newrow">
            <strong>Apply oil</strong>
            <select [(ngModel)]="newOil" (ngModelChange)="onSelectOil()">
                <option *ngFor="let oilSet of availableOils; trackBy:trackers.trackByIndex;" [ngValue]="oilSet">
                    {{oilSet.oil.amount !== 1 ? oilSet.oil.amount + " " : ""}}{{oilSet.oil.effectiveName()}}
                </option>
            </select>
        </div>
        <div class="newrow" *ngIf="item.oilsApplied.length">
            <strong>Applied oils</strong>
            <div class="newrow" *ngFor="let oil of item.oilsApplied; let index = index; trackBy:trackers.trackByIndex;">
                <span>
                    {{oil.effectiveName()}} ({{durationDescription(oil.duration)}})
                </span>
                <button class="lower" (click)="onRemoveOil(index)">Remove</button>
            </div>
        </div>
    </div>
</ng-container>
