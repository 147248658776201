<!-- eslint-disable @angular-eslint/template/cyclomatic-complexity -->
<ng-container *ngIf="skillChoiceParameters() as skillChoiceParameters">
    <div *ngIf="skillChoiceParameters.allowedIncreases" class="featchoice-container"
        [ngClass]="{'list-item': showTitle && !isTileMode, 'vlist': !showTitle, 'problem':(choice.increases.length > skillChoiceParameters.allowedIncreases || areAnyIncreasesIllegal), 'fullsize-only':skillChoiceParameters.cleared}">
        <!--Choice button shows in title mode-->
        <!--List mode button-->
        <button class="newrow left-aligned sublist-toggle skillincrease"
            [ngClass]="{'fancy-button choicecleared':(skillChoiceParameters.cleared), 'activechoice':shownChoice()===skillChoiceParameters.listId && !choice.showOnSheet}"
            (click)="toggleShownList(skillChoiceParameters.listId)"
            *ngIf="showTitle && (!isTileMode || choice.showOnSheet)">
            {{skillChoiceParameters.buttonTitle}}
        </button>
        <!--Tile mode button-->
        <button class="skillincrease" (click)="toggleShownList(skillChoiceParameters.listId)"
            *ngIf="showTitle && isTileMode && !choice.showOnSheet"
            [ngClass]="{'fancy-button choicecleared':(skillChoiceParameters.cleared), 'activechoice':shownChoice()===skillChoiceParameters.listId && !choice.showOnSheet}">
            <app-gridIcon class="skillincrease" [ngbTooltip]="skillChoiceParameters.buttonTitle"
                [superTitle]="skillChoiceParameters.allowedIncreases.toString()"
                [title]="gridIconTitle(skillChoiceParameters.allowedIncreases)"
                [ngClass]="{'fancy-button':skillChoiceParameters.cleared}">
            </app-gridIcon>
        </button>
        <!--Choice title shows above content in content only mode-->
        <div class="newrow list-item padding-8 center-aligned" *ngIf="!showTitle">
            <header class="box-header sectionHeader">
                {{skillChoiceParameters.buttonTitle}}
            </header>
            <div class="newrow center-aligned">
                <span>
                    <button class="center-aligned" *ngIf="choice.bonus" (click)="removeBonusSkillChoice(choice)">
                        Remove bonus skill choice
                    </button>
                </span>
            </div>
        </div>
        <!--Choice content shows in content mode-->
        <div id="{{!showTitle ? 'choiceArea' : ''}}" class="list-item"
            [ngClass]="{'sublist skillincrease':showTitle, 'fancy-list':showTitle && skillChoiceParameters.cleared}"
            *ngIf="showContent && shownChoice()===skillChoiceParameters.listId">
            <ng-container
                *ngFor="let skillParameters of availableSkillsParameters(choice, levelNumber, skillChoiceParameters.allowedIncreases); trackBy:trackers.trackByIndex;">
                <div class="list-item gridicon-fullsizebox" triggers="click"
                    [ngClass]="{'selected':skillParameters.increased}">
                    <input class="character-choice" id="{{skillParameters.skill.name}}" type="checkbox"
                        (change)="onSkillIncrease(skillParameters.skill.name, $event, choice, false, skillChoiceParameters.allowedIncreases)"
                        [checked]="skillParameters.checked" [disabled]="skillParameters.disabled">
                    <div class="gridicon-fullsizebox">
                        <app-gridIcon [title]="skillParameters.skill.name"
                            [ngbPopover]="skillLevelName(skillParameters.skillLevel, false)"
                            [superTitle]="skillLevelName(skillParameters.skillLevel, true)">
                        </app-gridIcon>
                        <header class="sectionHeader">
                            <span>
                                {{skillParameters.skill.name}}{{skillParameters.skill.ability ? " (" +
                                skillParameters.skill.ability.substr(0,3) + ")" : ""}}&nbsp;
                            </span>
                            <cite class="problem"
                                *ngFor="let reason of cannotIncreaseSkill(skillParameters.skill, levelNumber, choice); trackBy:trackers.trackByIndex;">
                                {{reason}}
                            </cite>
                            <cite class="problem" [ngbPopover]="'This skill was raised higher than allowed.'"
                                *ngIf="skillTooHigh(skillParameters.skill)">
                                Too high
                            </cite>
                        </header>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>
