<!-- eslint-disable @angular-eslint/template/cyclomatic-complexity -->
<!--Changing Aeon Stones-->
<div class="newrow" *ngFor="let index of availableSlots(); trackBy:trackers.trackByIndex;">
    <strong *ngIf="index === 0">Aeon Stones</strong>
    <span *ngIf="index !== 0">&nbsp;</span>
    <select [(ngModel)]="newAeonStone[index]" (change)="onSelectAeonStone(index)"
        [disabled]="!item.aeonStones[index-1] && index > 0">
        <ng-container
            *ngFor="let stone of initialAeonStones(index); let initialStoneIndex = index; trackBy:trackers.trackByIndex;">
            <option [ngValue]="stone" *ngIf="initialStoneIndex === 0">

            </option>
            <optgroup *ngIf="initialStoneIndex === 1" label="Current stone">
                <option [ngValue]="stone">
                    {{stone.aeonStone.name}}
                </option>
            </optgroup>
        </ng-container>
        <ng-container *ngFor="let inv of inventories(); trackBy:trackers.trackByIndex;">
            <ng-container *ngIf="availableAeonStones(inv) as availableAeonStones">
                <optgroup label="{{itemStore ? 'Available stones' : inventoryName(inv)}}"
                    *ngIf="availableAeonStones.length">
                    <option *ngFor="let stone of availableAeonStones; trackBy:trackers.trackByIndex;" [ngValue]="stone"
                        title="{{hint(stone.aeonStone)}}">
                        {{stone.aeonStone.amount > 1 ? stone.aeonStone.amount : ""}}
                        {{stone.aeonStone.name}}{{aeonStoneCooldownText(stone.aeonStone)}}
                    </option>
                </optgroup>
            </ng-container>
        </ng-container>
    </select>
</div>
