<!-- eslint-disable @angular-eslint/template/cyclomatic-complexity -->
<div class="vlist">
    <header class="subsectionHeader">
        Custom effects on {{objectName}}
    </header>
    <button class="newrow center-aligned" (click)="newCustomEffectOnThis()">Add effect</button>
    <div class="newrow list-item"
        *ngFor="let effect of customEffectsOnThis(); let index = index; trackBy:trackers.trackByIndex;">
        <ng-template #FormulaTemplate>
            <strong>Formula</strong>
            {{effect.setValue || effect.value || ''}}
        </ng-template>
        <span class="hlist">
            <strong class="no-shadow">Granted by</strong>
            <input type="text" id="customEffectSource{{objectName}}{{index}}" maxLength="50" [(ngModel)]="effect.source"
                (blur)="updateEffects()">
        </span>
        <span class="hlist">
            <strong class="no-shadow">Type</strong>
            <select [(ngModel)]="effect.type" (ngModelChange)="updateEffects()">
                <option *ngFor="let type of bonusTypes(); trackBy:trackers.trackByIndex;">
                    {{type}}
                </option>
            </select>
        </span>
        <span class="hlist" *ngIf="effectValueParameters(effect) as effectValueParameters"
            [ngSwitch]="effectValueParameters.displayType">
            <strong class="no-shadow">Value</strong>
            <ng-container *ngSwitchCase="'Formula'">
                <span>
                    <span class="value"
                        [ngClass]="{'absolute':effectValueParameters.formulaValue?.[0]==='=', 'bonus':effectValueParameters.formulaValue?.[0]==='+', 'penalty':effectValueParameters.formulaValue?.[0]==='-'}">
                        {{effectValueParameters.formulaValue}}
                    </span>
                    <i [ngbPopover]="FormulaTemplate" *ngIf="effectValueParameters.isFormula" class="bi-calculator"></i>
                </span>
            </ng-container>
            <ng-container *ngSwitchCase="'Toggle'">
                <span class="value absolute">
                    on
                </span>
            </ng-container>
            <ng-container *ngSwitchCase="'Value'">
                <input type="number" class="number4" id="customEffectValue{{objectName}}{{index}}" maxLength="30"
                    [(ngModel)]="effect.value" (blur)="validate(effect)">
            </ng-container>
        </span>
        <button style="flex-basis:auto;flex-grow:0;" (click)="removeCustomEffect(effect)">Remove</button>
    </div>
</div>
