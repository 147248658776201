<!-- eslint-disable @angular-eslint/template/cyclomatic-complexity -->
<div class="window-button-container">
    <button *ngIf="creature === 'Character'" class="minimizebutton lower"
        [ngbTooltip]="isMinimized ? 'Click to show all choices and information.' : 'Click to hide finished choices and show compact information.'"
        [ngClass]="{'fancy-button':isMinimized}" (click)="minimize()">
        <i class='bi-arrows-collapse' *ngIf="isMinimized"></i>
        <i class='bi-arrows-expand' *ngIf="!isMinimized"></i>
    </button>
    <button class="tilemodebutton" [ngClass]="{'fancy-button':isTileMode}" (click)="toggleTileMode()"
        [ngbTooltip]="isTileMode ? 'Click to enable list layout' : 'Click to enable tiled layout'">
        <!--Content is css only-->
        <div>
            <div> </div>
            <div> </div>
            <div> </div>
            <div> </div>
        </div>
    </button>
</div>
<div id="{{creature}}-inventory-height" class="attributeBox">
    <header class="sectionHeader box-header">
        Inventory
    </header>
    <div class="loading" *ngIf="stillLoading">
        Loading
    </div>
    <ng-container *ngIf="!stillLoading">
        <button class="fullsize-only list-item center-aligned" *ngIf="!itemStore"
            (click)="setItemsMenuTarget(creature); toggleItemsMenu()">
            Get Items
        </button>
        <header class="subsectionHeader" *ngIf="creature==='Character'">
            Currency
        </header>
        <div class="list-item" *ngIf="creature==='Character' && character.cash as cash">
            <strong>
                Platinum
            </strong>
            <div class="value">
                {{cash[0]}}
            </div>
            <strong>
                Gold
            </strong>
            <div class="value">
                {{cash[1]}}
            </div>
            <strong>
                Silver
            </strong>
            <div class="value">
                {{cash[2]}}
            </div>
            <strong>
                Copper
            </strong>
            <div class="value center-aligned">
                {{cash[3]}}
            </div>
            <button class="center-aligned" [ngbTooltip]="'Exchange coins for higher currency (no higher than Gold).'"
                (click)="sortCash();">
                Sort
            </button>
        </div>
        <header class="subsectionHeader">
            Bulk
        </header>
        <ng-container *ngIf="calculatedCreatureBulk() as calculatedBulk">
            <div class="list-item">
                <div class="newrow">
                    <strong>
                        Bulk
                    </strong>
                    <span>
                        <div class="value" [ngbPopover]="calculatedBulk.current.explain"
                            [ngClass]="{'penalty':calculatedBulk.currentpenalties, 'bonus':calculatedBulk.currentbonuses, 'absolute':calculatedBulk.currentabsolutes.length}">
                            {{calculatedBulk.current.value}}
                        </div>
                    </span>
                </div>
                <app-tags [creature]="creature" [objectName]="'Bulk'" [showTraits]=true [showFeats]=true
                    [showItems]=true [showActivities]=true [showConditions]=true [showEffects]=true></app-tags>
            </div>
            <ng-template #EncumbranceLimitEffectsPopoverContent>
                <div class="fullsize-only newrow">
                    <app-objectEffects [creature]="creature" [objectName]="'Encumbered Limit'"></app-objectEffects>
                </div>
            </ng-template>
            <div class="fullsize-only list-item">
                <div class="newrow">
                    <span class="hlist">
                        <span class="fullsize-only" [ngbPopover]="EncumbranceLimitEffectsPopoverContent"
                            #EncumbranceLimitEffectsPopover="ngbPopover" triggers="click">
                            <i [ngbTooltip]="!EncumbranceLimitEffectsPopover.isOpen() ? 'Edit effects' : ''"
                                class='bi-lightning-charge'></i>
                        </span>
                        <strong>
                            Encumbrance Limit
                        </strong>
                    </span>
                    <span>
                        <div class="value" [ngbPopover]="calculatedBulk.encumbered.explain"
                            [ngClass]="{'penalty':calculatedBulk.encumberedpenalties, 'bonus':calculatedBulk.encumberedbonuses, 'absolute':calculatedBulk.encumberedabsolutes.length}">
                            {{calculatedBulk.encumbered.value}}
                        </div>
                    </span>
                </div>
                <app-tags [creature]="creature" [objectName]="'Encumbered Limit'" [showTraits]=true [showFeats]=true
                    [showItems]=true [showActivities]=true [showConditions]=true [showEffects]=true></app-tags>
            </div>
            <ng-template #MaxBulkEffectsPopoverContent>
                <div class="fullsize-only newrow">
                    <app-objectEffects [creature]="creature" [objectName]="'Max Bulk'"></app-objectEffects>
                </div>
            </ng-template>
            <div class="fullsize-only list-item">
                <div class="newrow">
                    <span class="hlist">
                        <span class="fullsize-only" [ngbPopover]="MaxBulkEffectsPopoverContent"
                            #MaxBulkEffectsPopover="ngbPopover" triggers="click">
                            <i [ngbTooltip]="!MaxBulkEffectsPopover.isOpen() ? 'Edit effects' : ''"
                                class='bi-lightning-charge'></i>
                        </span>
                        <strong>
                            Maximum Bulk
                        </strong>
                    </span>
                    <span>
                        <div class="value" [ngbPopover]="calculatedBulk.max.explain"
                            [ngClass]="{'penalty':calculatedBulk.maxpenalties, 'bonus':calculatedBulk.maxbonuses, 'absolute':calculatedBulk.maxabsolutes.length}">
                            {{calculatedBulk.max.value}}
                        </div>
                    </span>
                </div>
                <app-tags [creature]="creature" [objectName]="'Max Bulk'" [showTraits]=true [showFeats]=true
                    [showItems]=true [showActivities]=true [showConditions]=true [showEffects]=true></app-tags>
            </div>
        </ng-container>
        <ng-container *ngIf="quickCraftingParameters() as quickCraftingParameters">
            <header class="sectionHeader fullsize-only box-header">
                Quick Crafting
            </header>
            <app-tags [creature]="creature" [objectName]="'Quick Crafting'" [showTraits]=true [showFeats]=true
                [showItems]=true [showActivities]=true [showConditions]=true [showEffects]=true></app-tags>
            <button class="list-item" *ngIf="quickCraftingParameters.snareSpecialist"
                (click)="toggleShownList('snarespecialist')">
                <header class="sectionHeader">
                    Snare Specialist
                </header>
            </button>
            <ng-container *ngIf="shownList()==='snarespecialist'">
                <div class="newrow" *ngIf="!itemsPreparedForQuickCrafting('snarespecialist').length">
                    You don't have any snares prepared for quick deployment.
                </div>
                <ng-container
                    *ngFor="let preparedItem of itemsPreparedForQuickCrafting('snarespecialist'); trackBy:trackers.trackByIndex;">
                    <div class="list-item" *ngIf="'snarespecialist'+preparedItem.item.id as inventoryID">
                        <button class="newrow sublist-toggle" (click)="toggleShownItem(inventoryID)"
                            [ngClass]="{'inactive-button':!preparedItem.learned.snareSpecialistAvailable}">
                            <span>
                                {{preparedItem.learned.snareSpecialistAvailable}}
                                {{preparedItem.item.effectiveName()}}
                            </span>
                            <span>
                                {{(preparedItem.item.level) ? "Level "+preparedItem.item.level : "&nbsp;"}}
                            </span>
                        </button>
                        <div class="list-item sublist lower" *ngIf="shownItem()===inventoryID"
                            [ngClass]="{'inactive-list':!preparedItem.learned.snareSpecialistAvailable}">
                            <div [ngbTooltip]="cannotCraftReason.join('\n')"
                                *ngIf="cannotCraftReason(preparedItem.item, preparedItem.learned, 'snarespecialist') as cannotCraftReason">
                                <button class="newrow center-aligned"
                                    (click)="craftItem(preparedItem.item, preparedItem.learned, 'snarespecialist')"
                                    [disabled]="cannotCraftReason.length">
                                    <span>
                                        Deploy Snare
                                        <app-actionIcons [actionString]="snareSpecialistActions(preparedItem.item)">
                                        </app-actionIcons>
                                    </span>
                                </button>
                            </div>
                            <app-item [item]=preparedItem.item [itemStore]=true></app-item>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
        <header class="sectionHeader fullsize-only box-header">
            Items
        </header>
        <app-tags [creature]="creature" [objectName]="'Inventory'" [showTraits]=true [showFeats]=true [showItems]=true
            [showActivities]=true [showConditions]=true [showEffects]=true></app-tags>
        <ng-template #MaxInvestedEffectsPopoverContent>
            <div class="fullsize-only newrow">
                <app-objectEffects [creature]="creature" [objectName]="'Max Invested'"></app-objectEffects>
            </div>
        </ng-template>
        <div class="fullsize-only list-item" *ngIf="investedParameters() as investedParameters">
            <strong>
                Invested
            </strong>
            <div class="value" [ngClass]="{'problem':(investedParameters.current > investedParameters.max)}">
                {{investedParameters.current}}
            </div>
            <span class="hlist">
                <span class="fullsize-only" [ngbPopover]="MaxInvestedEffectsPopoverContent"
                    #MaxInvestedEffectsPopover="ngbPopover" triggers="click">
                    <i [ngbTooltip]="!MaxInvestedEffectsPopover.isOpen() ? 'Edit effects' : ''"
                        class='bi-lightning-charge'></i>
                </span>
                <strong>
                    Max Invested
                </strong>
            </span>
            <div class="value right-aligned" [ngbPopover]="investedParameters.explain"
                [ngClass]="{'penalty':investedParameters.penalties, 'bonus':investedParameters.bonuses, 'absolute':investedParameters.absolutes}">
                {{investedParameters.max}}
            </div>
            <app-tags [creature]="creature" [objectName]="'Max Invested'" [showTraits]=true [showFeats]=true
                [showItems]=true [showActivities]=true [showConditions]=true [showEffects]=true></app-tags>
        </div>
        <div class="vlist" cdkDropListGroup>
            <ng-container
                *ngFor="let inventory of currentCreature.inventories; let inventoryIndex = index; trackBy:trackers.trackByIndex;">
                <div class="list-item" [ngClass]="{'fullsize-only':inventoryIndex > 1}">
                    <header class="sectionHeader fullsize-only newrow" *ngIf="inventory.bulkLimit">
                        {{inventoryName(inventory)}}
                        {{inventoryBulkDescription(inventory)}}
                    </header>
                    <div cdkDropList id="{{inventory.id}}|blank" (cdkDropListDropped)="dragdropInventoryItem($event)"
                        [ngClass]="{'icon-list':isTileMode, 'vlist fullwidth':!isTileMode}"
                        *ngIf="isInventoryEmpty(inventory)"
                        [ngStyle]="{'min-height': isTileMode ? null : '2em', 'display': isTileMode ? null : 'flex'}">
                    </div>
                    <ng-container *ngFor="let itemSet of inventory.names; trackBy:trackers.trackByIndex;">
                        <ng-container *ngIf="sortItemSet(inventory, itemSet.key) as itemList">
                            <header class="subsectionHeader newrow" *ngIf="itemList.length">
                                {{itemSet.name}}
                            </header>
                            <div cdkDropList id="{{inventory.id}}|{{itemSet.key}}"
                                (cdkDropListDropped)="dragdropInventoryItem($event)"
                                [ngClass]="{'icon-list':isTileMode, 'vlist fullwidth':!isTileMode}"
                                *ngIf="itemList.length" [ngStyle]="{'min-height': isTileMode ? null : '2em'}">
                                <ng-container
                                    *ngFor="let itemParameters of itemParameters(itemList); trackBy:trackers.trackByObjectId;">

                                    <ng-template #ItemIconsTemplate>
                                        <span [ngbTooltip]="material && material?.name"
                                            *ngIf="itemParameters.asEquipment?.material?.[0] as material">
                                            <i class='ra ra-diamond'></i>
                                        </span>
                                        <span [ngbTooltip]="'Broken'" *ngIf="itemParameters.asEquipment?.broken">
                                            <i class='ra ra-broken-shield'></i>
                                        </span>
                                        <span [ngbTooltip]="'Shoddy'" *ngIf="itemParameters.asEquipment?.shoddy"><i
                                                class='ra ra-broken-bottle'></i>
                                        </span>
                                        <span [ngbTooltip]="'Temporary Item'" *ngIf="itemParameters.item.expiration">
                                            <i class='ra ra-clockwork'></i>
                                        </span>
                                        <span [ngbTooltip]="'Large Weapon'" *ngIf="itemParameters.asWeapon?.large">
                                            <i class='ra ra-large-hammer'></i>
                                        </span>
                                        <span [ngbTooltip]="'Blade Ally'"
                                            *ngIf="itemParameters.asBladeAllyChangeable?.bladeAlly">
                                            <i class='ra ra-fireball-sword'></i>
                                        </span>
                                        <span [ngbTooltip]="'Battleforged'"
                                            *ngIf="itemParameters.asBattleforgedChangeable?.battleforged">
                                            <i class='ra ra-anvil'></i>
                                        </span>
                                        <span [ngbTooltip]="'Emblazon Armament'"
                                            *ngIf="itemParameters.hasEmblazonArmament">
                                            <i class='ra ra-fire-shield'></i>
                                        </span>
                                        <span [ngbTooltip]="'Emblazon Energy: ' + itemParameters.emblazonEnergyChoice"
                                            *ngIf="itemParameters.emblazonEnergyChoice">
                                            <i class='ra ra-bolt-shield'></i>
                                        </span>
                                        <span [ngbTooltip]="'Emblazon Antimagic'"
                                            *ngIf="itemParameters.hasEmblazonAntimagic">
                                            <i class='ra ra-eye-shield'></i>
                                        </span>
                                    </ng-template>
                                    <ng-template #ItemTitleTemplate>
                                        <span class="hlist left-aligned">
                                            <span *ngIf="itemParameters.canUse !== undefined">
                                                <i class="value bi-x penalty"
                                                    *ngIf="!isTileMode && itemParameters.canUse === false"
                                                    [ngbTooltip]="'You are not trained with this item.'"></i>
                                                <i class="value bi-check2 bonus"
                                                    *ngIf="!isTileMode && itemParameters.canUse"
                                                    [ngbTooltip]="'You are trained with this item.'"></i>
                                            </span>
                                            <ng-container *ngTemplateOutlet="ItemIconsTemplate">
                                            </ng-container>
                                            {{ ( itemParameters.item.amount !== undefined &&
                                            itemParameters.item.amount !== 1 ) ? itemParameters.item.amount+" " : ""
                                            }}
                                            {{itemParameters.item.effectiveName()}}
                                            <span class="lower" *ngIf="itemParameters.containedItemsAmount">
                                                contains
                                                {{itemParameters.containedItemsAmount}}
                                                {{itemParameters.containedItemsAmount === 1 ? "item" : "items"}}
                                            </span>
                                        </span>
                                        <span *ngIf="itemParameters.item.level" style="flex-basis:auto;flex-grow:0">
                                            Level {{itemParameters.item.level}}
                                        </span>
                                    </ng-template>
                                    <ng-template #ItemTooltipTemplate>
                                        <ng-container *ngTemplateOutlet="ItemIconsTemplate">
                                        </ng-container>
                                        {{(itemParameters.item.amount !== undefined && itemParameters.item.amount
                                        !== 1) ? itemParameters.item.amount+" " :
                                        ""}} {{itemParameters.item.effectiveName()}}
                                        <span class="lower" *ngIf="itemParameters.containedItemsAmount">
                                            contains
                                            {{itemParameters.containedItemsAmount}}
                                            {{itemParameters.containedItemsAmount === 1 ? "item" : "items"}}
                                        </span>
                                    </ng-template>
                                    <ng-template #ItemTemplate>
                                        <header class="spellHeader newrow" *ngIf="isTileMode">
                                            <ng-container *ngTemplateOutlet="ItemTitleTemplate">
                                            </ng-container>
                                        </header>
                                        <div class="newrow left-aligned" *ngIf="itemParameters.asArmor">
                                            <span
                                                [ngClass]="{'absolute': itemParameters.asArmor.hasProficiencyChanged(itemParameters.proficiency)}">
                                                {{itemParameters.asArmor.title()}}
                                            </span>
                                            <span
                                                *ngIf="itemParameters.asArmor.hasProficiencyChanged(itemParameters.proficiency)">
                                                (normally {{itemParameters.asArmor.prof}})
                                            </span>
                                        </div>
                                        <div class="newrow left-aligned" *ngIf="itemParameters.asWeapon">
                                            <span
                                                [ngClass]="{'absolute': itemParameters.asWeapon.hasProficiencyChanged(itemParameters.proficiency)}">
                                                {{ itemParameters.asWeapon.title({preparedProficiency:
                                                itemParameters.proficiency}) }}
                                            </span>
                                            <span
                                                *ngIf="itemParameters.asWeapon.hasProficiencyChanged(itemParameters.proficiency)">
                                                (normally {{itemParameters.asWeapon.prof}})
                                            </span>
                                        </div>
                                        <div class="newrow lower" *ngIf="itemParameters.item.expiration">
                                            <span>
                                                <strong>
                                                    Duration
                                                </strong>
                                                {{durationDescription(itemParameters.item.expiration)}}
                                                <span *ngIf="itemParameters.item.expiresOnlyIf">
                                                    {{itemParameters.item.expiresOnlyIf === "equipped" ?
                                                    "(The item only expires while equipped.)" :
                                                    "(The item only expires while not equipped.)"}}
                                                </span>
                                            </span>
                                        </div>
                                        <ng-container
                                            *ngIf="itemParameters.item.amount !== undefined && itemParameters.asImmediateStoredSpellItem">
                                            <ng-container
                                                *ngFor="let itemSpell of itemStoredSpell(itemParameters.item); trackBy:trackers.trackByIndex;">
                                                <app-spellTarget class="newrow vlist" [creature]="creature"
                                                    [phrase]="!!itemParameters.asWand?.cooldown ? 'Overcharge (Cooldown ' + durationDescription(itemParameters.asWand?.cooldown) + ') and cast' : 'Cast'"
                                                    [showActions]="true" [spell]="itemSpell.spell"
                                                    [gain]="itemSpell.gain"
                                                    [cannotCast]="itemParameters.asEquipment?.broken ? 'The item is broken and cannot be used.' : ''"
                                                    [effectiveSpellLevel]="itemSpell.choice.level"
                                                    (castMessage)="onUseSpellCastingItem(itemParameters.item, $event.target, inventory)">
                                                </app-spellTarget>
                                            </ng-container>
                                        </ng-container>
                                        <ng-container
                                            *ngIf="itemParameters.asConsumable && itemParameters.item.amount !== undefined">
                                            <button class="newrow center-aligned"
                                                [disabled]="!itemParameters.asConsumable.amount"
                                                (click)="onUseConsumable(itemParameters.asConsumable, creature, inventory)"
                                                *ngIf="itemParameters.item.amount !== undefined && (itemParameters.asGenericConsumable || itemParameters.asDrinkableConsumable)?.actions">
                                                <span>
                                                    Use
                                                    <app-actionIcons *ngIf="itemParameters.asConsumable.actions"
                                                        [actionString]="itemParameters.asConsumable.actions">
                                                    </app-actionIcons>
                                                    {{(itemParameters.asConsumable.activationType) ?
                                                    itemParameters.asConsumable.activationType : ""}}
                                                </span>
                                            </button>
                                            <ng-container
                                                *ngIf="!!itemParameters.asDrinkableConsumable && itemParameters.asDrinkableConsumable?.actions">
                                                <button class="newrow center-aligned"
                                                    *ngIf="!isManualMode && isCompanionAvailable()"
                                                    [disabled]="!itemParameters.asDrinkableConsumable.amount"
                                                    (click)="onUseConsumable(itemParameters.asDrinkableConsumable, creatureTypesEnum.AnimalCompanion, inventory)">
                                                    <span>
                                                        Use (Give to your animal companion)
                                                        <app-actionIcons
                                                            [actionString]="itemParameters.asDrinkableConsumable.actions">
                                                        </app-actionIcons>
                                                        {{(itemParameters.asDrinkableConsumable.activationType) ?
                                                        itemParameters.asDrinkableConsumable.activationType : ""}}
                                                    </span>
                                                </button>
                                                <button class="newrow center-aligned"
                                                    *ngIf="!isManualMode && isFamiliarAvailable()"
                                                    [disabled]="!itemParameters.item.amount"
                                                    (click)="onUseConsumable(itemParameters.asDrinkableConsumable, creatureTypesEnum.Familiar, inventory)">
                                                    <span>
                                                        Use (Give to your familiar)
                                                        <app-actionIcons
                                                            [actionString]="itemParameters.asDrinkableConsumable.actions">
                                                        </app-actionIcons>
                                                        {{(itemParameters.asDrinkableConsumable.activationType) ?
                                                        itemParameters.asDrinkableConsumable.activationType : ""}}
                                                    </span>
                                                </button>
                                            </ng-container>
                                        </ng-container>
                                        <ng-template #ContainerItemDropModal let-dropModal>
                                            <div class="modal-header">
                                                <header class="sectionHeader modal-title" id="modal-title">
                                                    <i class="ra ra-hive-emblem"></i>
                                                    Drop contained items?
                                                </header>
                                                <button type="button" class="close" aria-label="close"
                                                    (click)="dropModal.dismiss('Cross click')" ngbAutofocus>
                                                    <span aria-hidden="true">
                                                        &times;
                                                    </span>
                                                </button>
                                            </div>
                                            <div class="modal-body vlist">
                                                <div class="fullsize-scroll-box vlist" style="max-height: 50vh;">
                                                    <div class="list-item newrow left-aligned">
                                                        This item is a container with
                                                        {{itemParameters.containedItemsAmount}} item(s)
                                                        currently inside. You can drop the entire pack or keep
                                                        the contained items.
                                                        <div class="newrow lower">
                                                            If you choose to keep the contained items, they will
                                                            be moved loosely into your main inventory.
                                                        </div>
                                                    </div>
                                                    <button class="fullwidth btn btn-primary"
                                                        style="background-color: rgb(var(--accent))"
                                                        (click)="dropModal.close('Drop all')">
                                                        Drop this container and all the items inside
                                                    </button>
                                                    <button class="fullwidth btn btn-primary"
                                                        style="background-color: rgb(var(--accent))"
                                                        (click)="dropModal.close('Drop one')">
                                                        Drop this container, but keep the items inside
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-outline-secondary"
                                                    (click)="dropModal.dismiss('Cancel click')">
                                                    Cancel
                                                </button>
                                            </div>
                                        </ng-template>
                                        <ng-template #GrantingItemDropModal let-dropModal>
                                            <div class="modal-header">
                                                <header class="sectionHeader modal-title" id="modal-title">
                                                    <i class="ra ra-hive-emblem"></i>
                                                    Drop included items?
                                                </header>
                                                <button type="button" class="close" aria-label="close"
                                                    (click)="dropModal.dismiss('Cross click')" ngbAutofocus>
                                                    <span aria-hidden="true">
                                                        &times;
                                                    </span>
                                                </button>
                                            </div>
                                            <div class="modal-body vlist">
                                                <div class="fullsize-scroll-box vlist" style="max-height: 50vh;">
                                                    <div class="list-item newrow left-aligned">
                                                        This item originally came with additional items attached
                                                        or included. You can drop everything or just this item.
                                                        <div class="newrow lower">
                                                            If you choose to drop everything, the following
                                                            rules apply:
                                                        </div>
                                                        <div class="newrow lower">
                                                            <ul>
                                                                <li>
                                                                    Items that cannot stack (most equipment and
                                                                    some consumables) will only be dropped if
                                                                    they were explicitly granted by this item.
                                                                </li>
                                                                <li>
                                                                    For items that can stack, you will drop as
                                                                    many as you originally received with this
                                                                    item.
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <button class="fullwidth btn btn-primary"
                                                        style="background-color: rgb(var(--accent))"
                                                        (click)="dropModal.close('Drop all')">
                                                        Drop this item and all items granted by it
                                                    </button>
                                                    <button class="fullwidth btn btn-primary"
                                                        style="background-color: rgb(var(--accent))"
                                                        (click)="dropModal.close('Drop one')">
                                                        Drop this item, but keep all items granted by it
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-outline-secondary"
                                                    (click)="dropModal.dismiss('Cancel click')">
                                                    Cancel
                                                </button>
                                            </div>
                                        </ng-template>
                                        <!-- Upper row of buttons -->
                                        <div class="newrow">
                                            <ng-container
                                                *ngIf="itemParameters.asEquipment && investedParameters() as investedParameters">
                                                <span *ngIf="canEquipItem(itemParameters.item, inventoryIndex)">
                                                    <button *ngIf="!itemParameters.asEquipment.equipped"
                                                        (click)="onEquipItem(itemParameters.asEquipment, inventory, true)">
                                                        Equip
                                                    </button>
                                                    <button *ngIf="itemParameters.asEquipment.equipped"
                                                        (click)="onEquipItem(itemParameters.asEquipment, inventory, false)">
                                                        Unequip
                                                    </button>
                                                </span>
                                                <span *ngIf="canInvestItem(itemParameters.item, inventoryIndex)">
                                                    <button *ngIf="!itemParameters.asEquipment.invested"
                                                        (click)="onInvestItem(itemParameters.asEquipment, inventory, true)"
                                                        [disabled]="investedParameters.current >= investedParameters.max && !itemParameters.asEquipment.invested">
                                                        Invest
                                                    </button>
                                                    <button *ngIf="itemParameters.asEquipment.invested"
                                                        (click)="onInvestItem(itemParameters.asEquipment, inventory, false)">
                                                        Uninvest
                                                    </button>
                                                </span>
                                            </ng-container>
                                            <span class="gap-text" *ngIf="itemParameters.asStackable">
                                                <button (click)="onAmountChange(itemParameters.asStackable, -1)"
                                                    [disabled]="itemParameters.asStackable.amount <= 0">
                                                    -1
                                                </button>
                                                <button (click)="onAmountChange(itemParameters.asStackable, 1)">
                                                    +1
                                                </button>
                                            </span>
                                            <span
                                                *ngIf="!canEquipItem(itemParameters.item, inventoryIndex) && !canInvestItem(itemParameters.item, inventoryIndex) && !itemParameters.asStackable">
                                                &nbsp;
                                                <!-- This span is a placeholder that appears if no other button is valid on the left side of the Drop button -->
                                            </span>
                                            <ng-container *ngIf="canDropItem(itemParameters.item)">
                                                <span>
                                                    <app-itemTarget [item]="itemParameters.item" [inventory]="inventory"
                                                        [creature]="creature"
                                                        (moveMessage)="moveInventoryItem(itemParameters.item, inventory, $event.target, $event.amount, $event.including)">
                                                    </app-itemTarget>
                                                </span>
                                                <ng-container [ngSwitch]="showDropAllButtons(itemParameters.item)">
                                                    <span *ngSwitchCase="true">
                                                        <button
                                                            (click)="openGrantingOrContainerItemDropModal(GrantingItemDropModal, itemParameters.item, inventory)"
                                                            [ngbTooltip]="'Choose what to do with included items'">
                                                            <i class="ra ra-hive-emblem"></i>
                                                            Drop...
                                                        </button>
                                                    </span>
                                                    <ng-container *ngSwitchCase="false">
                                                        <span *ngIf="itemParameters.containedItemsAmount">
                                                            <button
                                                                (click)="openGrantingOrContainerItemDropModal(ContainerItemDropModal, itemParameters.item, inventory)"
                                                                [ngbTooltip]="'Choose what to do with contained items'">
                                                                <i class="ra ra-hive-emblem"></i>
                                                                Drop...
                                                            </button>
                                                        </span>
                                                        <span *ngIf="!itemParameters.containedItemsAmount">
                                                            <button
                                                                (click)="dropInventoryItem(itemParameters.item, inventory)">
                                                                Drop
                                                            </button>
                                                        </span>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                        <!-- Lower row of buttons -->
                                        <div class="newrow"
                                            *ngIf="itemParameters.item.tradeable && effectivePrice(itemParameters.item) as effectivePrice">
                                            <span class="gap-text"
                                                *ngIf="itemParameters.stack && itemParameters.asStackable as stackable; else CannotStack">
                                                <span
                                                    [ngbTooltip]="(stackable.amount <= itemParameters.stack) ? 'Use the sell button to sell the last item of a stack' : 'Sell at half price'">
                                                    <button
                                                        (click)="onAmountChange(stackable, -itemParameters.stack, true)"
                                                        [disabled]="stackable.amount <= itemParameters.stack">
                                                        Sell {{itemParameters.stack}}
                                                    </button>
                                                </span>
                                                <button (click)="onAmountChange(stackable, itemParameters.stack, true)"
                                                    [disabled]="!characterHasFunds(effectivePrice)">
                                                    Buy {{itemParameters.stack}}
                                                </button>
                                            </span>
                                            <ng-template #CannotStack>
                                                <span>
                                                    &nbsp;
                                                    <!-- This span is a placeholder that appears if no other button is valid on the left side of the Sell button -->
                                                </span>
                                            </ng-template>
                                            <span>
                                                <span
                                                    [ngbTooltip]="(itemParameters.asEquipment?.broken || itemParameters.asEquipment?.shoddy) ? 'The item is worthless in this state' : 'Sell at half price'">
                                                    <button
                                                        (click)="dropInventoryItem(itemParameters.item, inventory, true)"
                                                        [disabled]="itemParameters.item.amount <= 0 || itemParameters.asEquipment?.broken || itemParameters.asEquipment?.shoddy">
                                                        Sell
                                                        {{showDropAllButtons(itemParameters.item)
                                                        ? "with all parts"
                                                        : ""}}
                                                        {{itemParameters.containedItemsAmount
                                                        ? "without contained items"
                                                        : ""}}
                                                    </button>
                                                </span>
                                            </span>
                                        </div>
                                        <div class="list-item newrow left-aligned">
                                            <span *ngIf="itemParameters.asEquipment">
                                                <label for="{{itemParameters.asEquipment.id}}showname">
                                                    <div class="button no-animation"
                                                        [ngClass]="{'fancy-button': itemParameters.asEquipment.showName}">
                                                        <input class="invisible"
                                                            id="{{itemParameters.asEquipment.id}}showname"
                                                            type="checkbox"
                                                            [(ngModel)]="itemParameters.asEquipment.showName">
                                                        <i class='bi-pencil-square'></i>
                                                        Name
                                                    </div>
                                                </label>
                                            </span>
                                            <span>
                                                <label for="{{itemParameters.item.id}}shownotes">
                                                    <div class="button no-animation"
                                                        [ngClass]="{'fancy-button': itemParameters.item.showNotes}">
                                                        <input class="invisible"
                                                            id="{{itemParameters.item.id}}shownotes" type="checkbox"
                                                            [(ngModel)]="itemParameters.item.showNotes">
                                                        <i class='bi-pencil-square'></i>
                                                        Notes
                                                    </div>
                                                </label>
                                            </span>
                                            <span *ngIf="itemParameters.asRuneChangeable">
                                                <label for="{{itemParameters.asRuneChangeable.id}}showrunes">
                                                    <div class="button no-animation"
                                                        [ngClass]="{'fancy-button': itemParameters.asRuneChangeable.showRunes}">
                                                        <input class="invisible"
                                                            id="{{itemParameters.asRuneChangeable.id}}showrunes"
                                                            type="checkbox"
                                                            [(ngModel)]="itemParameters.asRuneChangeable.showRunes">
                                                        <i class='bi-pencil-square'></i>
                                                        Runes
                                                    </div>
                                                </label>
                                            </span>
                                            <span *ngIf="itemParameters.asStatusChangeable">
                                                <label for="{{itemParameters.asStatusChangeable.id}}showstatus">
                                                    <div class="button no-animation"
                                                        [ngClass]="{'fancy-button': itemParameters.asStatusChangeable.showStatus}">
                                                        <input class="invisible"
                                                            id="{{itemParameters.asStatusChangeable.id}}showstatus"
                                                            type="checkbox"
                                                            [(ngModel)]="itemParameters.asStatusChangeable.showStatus">
                                                        <i class='bi-pencil-square'></i>
                                                        Status
                                                    </div>
                                                </label>
                                            </span>

                                            <!--Changing Name-->
                                            <div class="list-item newrow left-aligned"
                                                [ngbCollapse]="!itemParameters.asEquipment.showName"
                                                *ngIf="itemParameters.asEquipment">
                                                <span>
                                                    Name
                                                </span>
                                                <input type="textarea" class="fullwidth"
                                                    [(ngModel)]="itemParameters.item.displayName"
                                                    (blur)="onItemChange(itemParameters.item)">
                                            </div>
                                            <!--Changing Notes-->
                                            <div class="list-item newrow left-aligned"
                                                [ngbCollapse]="!itemParameters.item.showNotes">
                                                <span>
                                                    Notes
                                                </span>
                                                <input type="textarea" class="fullwidth"
                                                    [(ngModel)]="itemParameters.item.notes">
                                            </div>
                                            <!--Changing Runes-->
                                            <app-itemRunes class="newrow"
                                                [ngbCollapse]="!itemParameters.asRuneChangeable.showRunes"
                                                *ngIf="itemParameters.asRuneChangeable"
                                                [item]="itemParameters.asRuneChangeable" [itemStore]=false
                                                [itemRoles]="itemParameters">
                                            </app-itemRunes>
                                            <!--Changing Status-->
                                            <div class="list-item newrow left-aligned"
                                                [ngbCollapse]="!(itemParameters.asStatusChangeable?.showStatus || itemParameters.asEquipment?.broken || itemParameters.asEquipment?.shoddy)"
                                                *ngIf="itemParameters.asStatusChangeable || itemParameters.asEquipment?.broken || itemParameters.asEquipment?.shoddy">
                                                <span>
                                                    Status
                                                </span>
                                                <div class="newrow"
                                                    *ngIf="itemParameters.asEquipment && { allowed: isRepairAllowed(itemParameters.asEquipment) } as repairAllowedStatus">
                                                    <span
                                                        [ngbPopover]="!repairAllowedStatus.allowed ? 'To repair a broken shield, restore its hit points above the Broken Threshold.' : ''">
                                                        <input id="{{itemParameters.item.id}}broken" type="checkbox"
                                                            [(ngModel)]="itemParameters.asEquipment.broken"
                                                            (ngModelChange)="onItemBroken(itemParameters.asEquipment)"
                                                            [disabled]="!repairAllowedStatus.allowed">
                                                        <label for="{{itemParameters.item.id}}broken">
                                                            <i class='ra ra-broken-shield'></i>
                                                            Broken
                                                        </label>
                                                        <ng-template #BrokenDescTemplate>
                                                            <div class="newrow left-aligned popover-keepalive">
                                                                A broken object can't be used for its normal
                                                                function, nor does it grant bonuses - with the
                                                                exception of armor. Broken armor still grants
                                                                its item bonus to AC, but it also imparts a
                                                                status penalty to AC depending on its category:
                                                                -1 for broken light armor, -2 for broken medium
                                                                armor, or -3 for broken heavy armor. A broken
                                                                item still imposes penalties and limitations
                                                                normally incurred by carrying, holding, or
                                                                wearing it. For example, broken armor would
                                                                still impose its Dexterity modifier cap, check
                                                                penalty, and so forth.
                                                            </div>
                                                        </ng-template>
                                                        <i class="bi-question-circle"
                                                            [ngbPopover]="BrokenDescTemplate"></i>
                                                    </span>
                                                </div>
                                                <div class="newrow" *ngIf="itemParameters.asEquipment">
                                                    <span>
                                                        <input id="{{itemParameters.asEquipment.id}}shoddy"
                                                            type="checkbox"
                                                            [(ngModel)]="itemParameters.asEquipment.shoddy"
                                                            (ngModelChange)="onItemChange(itemParameters.asEquipment)">
                                                        <label for="{{itemParameters.asEquipment.id}}shoddy">
                                                            <i class='ra ra-broken-bottle'></i>
                                                            Shoddy
                                                        </label>
                                                        <ng-template #ShoddyDescTemplate>
                                                            <div class="newrow left-aligned popover-keepalive">
                                                                When available, a shoddy item usually costs half
                                                                the Price of a standard item, though you can
                                                                never sell one in any case. Attacks and checks
                                                                involving a shoddy item take a -2 item penalty.
                                                                This penalty also applies to any DCs that a
                                                                shoddy item applies to (such as AC, for shoddy
                                                                armor). A shoddy suit of armor also worsens the
                                                                armor's check penalty by 2. A shoddy item's
                                                                Hit Points and Broken Threshold are each half
                                                                that of a normal item of its type.
                                                            </div>
                                                        </ng-template>
                                                        <i class="bi-question-circle"
                                                            [ngbPopover]="ShoddyDescTemplate"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="list-item newrow"
                                                *ngIf="itemParameters.asWeapon && (isLargeWeaponAllowed(itemParameters.item) || itemParameters.asWeapon?.large)">
                                                <span>
                                                    <input id="{{itemParameters.asWeapon.id}}large" type="checkbox"
                                                        [(ngModel)]="itemParameters.asWeapon.large"
                                                        (ngModelChange)=onItemChange(itemParameters.item)>
                                                    <label for="{{itemParameters.asWeapon.id}}large">
                                                        <i class='ra ra-large-hammer'></i>
                                                        Large
                                                    </label>
                                                    <ng-template #LargeDescTemplate>
                                                        <div class="newrow left-aligned popover-keepalive">
                                                            You can use a weapon built for a Large creature if
                                                            you are Small or Medium (both normally and when
                                                            raging). If you're not Small or Medium, you can use
                                                            a weapon built for a creature one size larger than
                                                            you. While wielding the weapon in combat, your
                                                            additional damage from Rage increases from 2 to 6,
                                                            and you have the clumsy 1 condition because of its
                                                            unwieldy size.
                                                        </div>
                                                    </ng-template>
                                                    <i class="bi-question-circle" [ngbPopover]="LargeDescTemplate"></i>
                                                </span>
                                            </div>
                                            <div class="list-item newrow"
                                                *ngIf="itemParameters.asBladeAllyChangeable && (itemParameters.asBladeAllyChangeable?.bladeAlly || isBladeAllyAllowed(itemParameters.item)) && { used: isBladeAllyUsed() } as bladeAllyStatus">
                                                <div class="newrow">
                                                    <span
                                                        [ngbPopover]="(!itemParameters.asBladeAllyChangeable.bladeAlly && bladeAllyStatus.used) ? 'Another item is already designated as your blade ally.' : ''">
                                                        <input id="{{itemParameters.asBladeAllyChangeable.id}}bladeally"
                                                            type="checkbox"
                                                            [(ngModel)]="itemParameters.asBladeAllyChangeable.bladeAlly"
                                                            (ngModelChange)=onItemChange(itemParameters.item)
                                                            [disabled]="!itemParameters.asBladeAllyChangeable.bladeAlly && bladeAllyStatus.used">
                                                        <label
                                                            for="{{itemParameters.asBladeAllyChangeable.id}}bladeally">
                                                            <i class='ra ra-fireball-sword'></i>
                                                            Blade Ally
                                                        </label>
                                                        <ng-template #BladeAllyDescTemplate>
                                                            <div class="newrow left-aligned popover-keepalive">
                                                                You can select one weapon or handwraps of mighty
                                                                blows when you make your daily preparations. In
                                                                your hands, the item gains the effect of a
                                                                property rune and you also gain the weapon's
                                                                critical specialization effect. For a champion
                                                                following the tenets of good, choose disrupting,
                                                                ghost touch, returning, or shifting. For a
                                                                champion following the tenets of evil, choose
                                                                fearsome, returning, or shifting.
                                                            </div>
                                                        </ng-template>
                                                        <i class="bi-question-circle"
                                                            [ngbPopover]="BladeAllyDescTemplate"></i>
                                                    </span>
                                                </div>
                                                <app-itemBladeAlly class="list-item newrow"
                                                    [item]=itemParameters.asBladeAllyChangeable
                                                    *ngIf="itemParameters.asBladeAllyChangeable.bladeAlly">
                                                </app-itemBladeAlly>
                                            </div>
                                            <app-itemEmblazonArmament class="fullwidth"
                                                [item]=itemParameters.asEmblazonArmamentChangeable
                                                *ngIf="itemParameters.asEmblazonArmamentChangeable && (itemParameters.asEmblazonArmamentChangeable?.emblazonArmament?.length || isEmblazonArmamentAllowed(itemParameters.item))">
                                            </app-itemEmblazonArmament>
                                            <div class="list-item newrow"
                                                *ngIf="itemParameters.asBattleforgedChangeable && (itemParameters.asBattleforgedChangeable?.battleforged || isBattleforgedAllowed(itemParameters.item))">
                                                <span>
                                                    <input id="{{itemParameters.item.id}}battleforged" type="checkbox"
                                                        [(ngModel)]="itemParameters.asBattleforgedChangeable.battleforged"
                                                        (ngModelChange)=onItemChange(itemParameters.item)>
                                                    <label for="{{itemParameters.item.id}}battleforged">
                                                        <i class='ra ra-anvil'></i>
                                                        Battleforged
                                                    </label>
                                                    <ng-template #BattleforgedDescTemplate>
                                                        <div class="newrow left-aligned popover-keepalive">
                                                            You can sharpen weapons, polish armor, and apply
                                                            special techniques to temporarily gain better
                                                            effects from your armaments. By spending 1 hour
                                                            working on a weapon or armor, you can grant it the
                                                            effects of a +1 potency rune until your next daily
                                                            preparations, gaining a +1 item bonus to attack
                                                            rolls for a weapon or increasing armor's item bonus
                                                            to AC by 1. This has no effect if the weapon or
                                                            armor already had a potency rune.
                                                        </div>
                                                    </ng-template>
                                                    <i class="bi-question-circle"
                                                        [ngbPopover]="BattleforgedDescTemplate"></i>
                                                </span>
                                            </div>
                                            <app-itemOils class="hide-empty" [item]=itemParameters.item
                                                [itemStore]=false *ngIf="itemParameters.item.type !== 'oils'">
                                            </app-itemOils>
                                            <app-itemPoisons class="hide-empty" [item]=itemParameters.asWeapon
                                                [itemStore]=false *ngIf="itemParameters.asWeapon">
                                            </app-itemPoisons>
                                            <app-itemTalismans [item]="itemParameters.asTalismanChangeable"
                                                [itemStore]=false
                                                *ngIf="itemParameters.asTalismanChangeable && canApplyTalismans(itemParameters.asTalismanChangeable)">
                                            </app-itemTalismans>
                                            <app-itemTalismanCords [item]=itemParameters.asTalismanChangeable
                                                *ngIf="itemParameters.asTalismanChangeable && canApplyTalismanCords(itemParameters.asTalismanChangeable)">
                                            </app-itemTalismanCords>
                                            <app-itemAeonStones class="list-item hide-empty"
                                                [item]="itemParameters.asWornItem" [itemStore]=false
                                                *ngIf="itemParameters.asWornItem && itemParameters.asWornItem?.isWayfinder">
                                            </app-itemAeonStones>
                                            <!-- Too many slotted Aeon Stones warning -->
                                            <div class="newrow list-item problem"
                                                *ngIf="itemParameters.asWornItem && itemHasBlockedSlottedAeonStones(itemParameters.asWornItem)">
                                                You have invested too many wayfinders with aeon stones slotted
                                                in them. All effects of these aeon stones are disabled.
                                            </div>
                                            <ng-container *ngIf="itemParameters.asShield">
                                                <div class="list-item newrow">
                                                    <strong>
                                                        HP
                                                    </strong>
                                                    <div class="value">
                                                        {{itemParameters.asShield.currentHitPoints()}}
                                                    </div>
                                                    <strong>
                                                        Max HP
                                                    </strong>
                                                    <div class="value">
                                                        {{itemParameters.asShield.effectiveMaxHP()}}
                                                    </div>
                                                    <strong>
                                                        BT
                                                    </strong>
                                                    <div class="value">
                                                        {{itemParameters.asShield.effectiveBrokenThreshold()}}
                                                    </div>
                                                </div>
                                                <div class="list-item newrow">
                                                    <ng-template #DamageSliderTemplate>
                                                        <div class="popover-keepalive slider-container"
                                                            [style.--name]="'\'Damage: ' + shieldDamage + '\''"
                                                            style="min-width:25vw; max-width:100%;">
                                                            <input class="slider" type="range" min="0"
                                                                max="{{itemParameters.asShield.effectiveMaxHP()}}"
                                                                [(ngModel)]="shieldDamage">
                                                        </div>
                                                    </ng-template>
                                                    <span>
                                                        <button [disabled]="shieldDamage === 0"
                                                            (click)="onShieldHPChange(itemParameters.asShield, shieldDamage)">
                                                            Damage
                                                        </button>
                                                    </span>
                                                    <span class="hlist center-aligned">
                                                        <input [ngbPopover]="DamageSliderTemplate"
                                                            #DamagePopover="ngbPopover" triggers="manual"
                                                            (focus)="DamagePopover.open()" class="number3" type="number"
                                                            [(ngModel)]="shieldDamage" maxLength="3" min="0"
                                                            max="{{itemParameters.asShield.effectiveMaxHP()}}"
                                                            (keypress)="positiveNumbersOnly($event)">
                                                    </span>
                                                    <span class="right-aligned">
                                                        <button [disabled]="shieldDamage === 0"
                                                            (click)="onShieldHPChange(itemParameters.asShield, -shieldDamage)">
                                                            Repair
                                                        </button>
                                                    </span>
                                                </div>
                                            </ng-container>
                                        </div>
                                        <app-item class="lower" [creature]="creature" [item]=itemParameters.item
                                            [allowActivate]="itemParameters.item.investedOrEquipped()"
                                            [armoredSkirt]="itemParameters.asArmor?.armoredSkirt(currentCreature)">
                                        </app-item>
                                    </ng-template>
                                    <ng-container *ngIf="itemParameters.item.id as inventoryID">
                                        <ng-container *ngIf="!isTileMode">
                                            <div class="list-item" cdkDrag [cdkDragData]="itemParameters.item"
                                                [cdkDragDisabled]="!canDropItem(itemParameters.item)">
                                                <button class="newrow sublist-toggle"
                                                    [ngClass]="{'fancy-button':itemParameters.item.investedOrEquipped(), 'problem':itemParameters.asEquipment?.broken}"
                                                    (click)="toggleShownItem(inventoryID)">
                                                    <ng-container *ngTemplateOutlet="ItemTitleTemplate">
                                                    </ng-container>
                                                </button>
                                                <div class="list-item sublist"
                                                    [ngClass]="{'fancy-list':itemParameters.item.investedOrEquipped(), 'problem':itemParameters.asEquipment?.broken}"
                                                    *ngIf="shownItem()===inventoryID">
                                                    <ng-container *ngTemplateOutlet="ItemTemplate">
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="isTileMode">
                                            <button [stickyPopover]="ItemTemplate" triggers="click"
                                                [ngClass]="{'fancy-button':itemParameters.item.investedOrEquipped(), 'problem':itemParameters.asEquipment?.broken, 'penalty': itemParameters.canUse === false, 'bonus': itemParameters.canUse}"
                                                (click)="toggleShownItem()" cdkDrag [cdkDragData]="itemParameters.item"
                                                [cdkDragDisabled]="!canDropItem(itemParameters.item)">
                                                <app-gridIcon [updateId]="itemParameters.item.id"
                                                    [ngClass]="{'fancy-button':itemParameters.item.investedOrEquipped(), 'problem':itemParameters.asEquipment?.broken, 'penalty': itemParameters.canUse === false, 'bonus': itemParameters.canUse}"
                                                    [ngbTooltip]="ItemTooltipTemplate"
                                                    [title]="itemParameters.item.gridIconTitle()"
                                                    [detail]="'noparse|' + (itemParameters.item.level ? itemParameters.item.level.toString() : '')"
                                                    [item]="itemParameters.item">
                                                </app-gridIcon>
                                            </button>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </ng-container>
                    </ng-container>
                    <!--Miscellaneous Items-->
                    <header class="subsectionHeader fullsize-only">
                        Miscellaneous Items
                    </header>
                    <button class="fullsize-only newrow center-aligned" (click)="addNewOtherItem(inventory)">
                        Add
                    </button>
                    <div class="fullsize-only list-item"
                        *ngFor="let item of inventory.otheritems; let index = index; trackBy:trackers.trackByIndex;">
                        <strong>
                            Name
                        </strong>
                        <input id="miscellaneousItemName{{inventoryIndex}}_{{index}}" type="text"
                            [(ngModel)]="item.name">
                        <strong>
                            Bulk
                        </strong>
                        <input
                            id="miscellaneousItemBulk{{inventoryIndex}}_{{index}}"
                            class="number2"
                            [ngModel]="item.bulk"
                            (blur)="validateBulk(item)"
                            (beforeinput)="bulkOnlyInputValidation($event, item.bulk)"
                            (ngModelChange)="item.bulk = $event.toUpperCase()"
                        >
                        <button (click)="removeOtherItem(item, inventory)">
                            Drop
                        </button>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>
</div>
