<!-- eslint-disable @angular-eslint/template/cyclomatic-complexity -->
<ng-container *ngIf="(choice.source+choice.id) as listId">
    <ng-container *ngIf="allowedAmount(choice) as allowedFeatsAmount">
        <ng-container *ngIf="buttonTitle(allowedFeatsAmount) as buttonTitle">
            <div *ngIf="allowedFeatsAmount > 0 && !shouldHideChoice(choice, allowedFeatsAmount)"
                class="featchoice-container"
                [ngClass]="{'list-item': showTitle && !isTileMode, 'vlist': !showTitle, 'problem':(areAnyFeatsIllegal || choice.feats.length > allowedFeatsAmount), 'fullsize-only':(allowedFeatsAmount === choice.feats.length)}">
                <!--Choice button shows in title mode-->
                <!--List mode button-->
                <button class="newrow left-aligned sublist-toggle" *ngIf="showTitle && (!isTileMode || showContent)"
                    [ngClass]="{'fancy-button choicecleared':(allowedFeatsAmount === choice.feats.length), 'featchoice':!choice.specialChoice && !choice.showOnSheet && (choice.type !== 'Familiar'), 'specialchoice':choice.specialChoice && !choice.showOnSheet, 'activechoice':shownChoice()===listId && (!choice.showOnSheet && (choice.type !== 'Familiar'))}"
                    (click)="toggleShownList(listId)">
                    {{buttonTitle}}
                </button>
                <!--Tile mode button-->
                <button (click)="toggleShownList(listId)" *ngIf="showTitle && !showContent && isTileMode"
                    [ngClass]="{'fancy-button choicecleared':(allowedFeatsAmount === choice.feats.length), 'featchoice':!choice.specialChoice, 'specialchoice':choice.specialChoice, 'activechoice':shownChoice()===listId}">
                    <app-gridIcon [ngbTooltip]="buttonTitle" [superTitle]="allowedFeatsAmount.toString()"
                        [title]="gridIconTitle(allowedFeatsAmount, choice)"
                        [detail]="(allowedFeatsAmount === 1 && choice.feats.length) ? (choice.feats[0].name.split(': ')[1] || '') : ''"
                        [ngClass]="{'fancy-button':(allowedFeatsAmount === choice.feats.length), 'featchoice':!choice.specialChoice, 'specialchoice':choice.specialChoice}"
                        [subTitle]="choice.feats.length ? '' : choice.type.split(' ')[choice.type.split(' ').length-1].substr(0,8)">
                    </app-gridIcon>
                </button>
                <!--Choice title shows above content in content only mode-->
                <div class="newrow list-item padding-8 center-aligned" *ngIf="!showTitle">
                    <header class="box-header sectionHeader">
                        {{buttonTitle}}
                    </header>
                    <div class="newrow center-aligned">
                        <span>
                            <button class="center-aligned" *ngIf="choice.bonus" (click)="removeBonusFeatChoice(choice)">
                                Remove bonus feat choice
                            </button>
                        </span>
                    </div>
                </div>
                <!--Choice content shows in content mode-->
                <div id="{{!showTitle ? 'choiceArea' : ''}}" class="list-item"
                    [ngClass]="{'sublist':showTitle, 'fancy-list':showTitle && (allowedFeatsAmount === choice.feats.length), 'featchoice':showTitle && !choice.specialChoice && !choice.showOnSheet && (choice.type !== 'Familiar'), 'specialchoice': showTitle && choice.specialChoice && !choice.showOnSheet}"
                    *ngIf="showContent && shownChoice()===listId">
                    <!-- Filter -->
                    <div class="list-item lower">
                        <strong>Filter</strong>
                        <div class="list-item gridicon-fullsizebox">
                            <input class="character-choice" id="unavailableFeats" type="checkbox"
                                [(ngModel)]="unavailableFeats">
                            <label for="unavailableFeats">
                                <strong>
                                    Show unavailable {{choice.specialChoice ? 'options' : 'feats'}}
                                </strong>
                            </label>
                        </div>
                        <div class="list-item gridicon-fullsizebox"
                            *ngIf="!choice.specialChoice && choice.type !== 'Familiar'">
                            <input class="character-choice" id="lowerLevelFeats" type="checkbox"
                                [(ngModel)]="lowerLevelFeats">
                            <label for="lowerLevelFeats">
                                <strong>
                                    Show lower level feats
                                </strong>
                            </label>
                        </div>
                        <div class="fullwidth" [ngbCollapse]="!unavailableFeats"
                            *ngIf="!choice.specialChoice && choice.type !== 'Familiar'">
                            <div class="list-item gridicon-fullsizebox">
                                <input class="character-choice" id="higherLevelFeats" type="checkbox"
                                    [(ngModel)]="higherLevelFeats">
                                <label for="higherLevelFeats">
                                    <strong>
                                        Show higher level feats
                                    </strong>
                                </label>
                            </div>
                        </div>
                        <div class="list-item gridicon-fullsizebox"
                            *ngIf="!choice.specialChoice && choice.type !== 'Familiar'">
                            <input class="character-choice" id="archetypeFeats" type="checkbox"
                                [(ngModel)]="archetypeFeats">
                            <label for="archetypeFeats">
                                <strong>
                                    Show archetype feats
                                </strong>
                            </label>
                        </div>
                    </div>
                    <!-- End Filter -->
                    <ng-container *ngIf="availableFeatsParameters(choice, allowedFeatsAmount) as featParametersList">
                        <div class="list-item" *ngIf="availableFeatsCount(featParametersList) < allowedFeatsAmount">
                            <span>There are fewer results available than you are allowed to take. This may be due to a
                                choice with limited options, and it might be intended at this time.</span>
                            <span *ngIf="!higherLevelFeats || !lowerLevelFeats">More results may be found if you allow
                                lower and higher level feats.</span>
                            <span *ngIf="choice.showOnCurrentLevel">This feat choice is always available on your current
                                character level. This could change the number of available feats at a later time.</span>
                        </div>
                        <ng-container *ngFor="let featParameters of featParametersList; trackBy:trackByFeat;">
                            <ng-template #FeatChoiceDetailTemplate>
                                <div class="newrow">
                                    <header class="spellHeader">{{featParameters.feat.displayName ||
                                        featParameters.feat.name}}</header>
                                    <div class="newrow">
                                        <label>
                                            <div class="button newrow no-animation"
                                                *ngIf="!featParameters.feat.subTypes && featParameters.available"
                                                [ngClass]="{'fancy-button':featParameters.taken, 'disabled':featParameters.disabled}">

                                                <input type="checkbox"
                                                    (change)="onFeatTaken(featParameters.feat, $event, choice, false)"
                                                    [checked]="featParameters.checked"
                                                    [disabled]="featParameters.disabled" hidden>
                                                {{featParameters.taken ? "Remove" : "Choose"}}
                                            </div>
                                        </label>
                                    </div>
                                    <div class="newrow left-aligned">
                                        <cite [ngbPopover]="reason.explain" class="problem"
                                            *ngFor="let reason of featParameters.cannotTake; trackBy:trackers.trackByIndex;">
                                            {{reason.reason}}
                                        </cite>
                                    </div>
                                    <app-feat class="newrow" [feat]="featParameters.feat" [choice]="choice"
                                        [levelNumber]="levelNumber" [featLevel]="featLevel"></app-feat>
                                    <!--Subfeats-->
                                    <ng-container
                                        *ngFor="let subFeatParameters of availableSubFeatParameters(allowedFeatsAmount, choice, featParameters.feat); trackBy:trackBySubType;">

                                        <div class="newrow list-item"
                                            [ngClass]="{'selected':(subFeatParameters.taken && true), 'unavailable':(!subFeatParameters.available)}"
                                            style="justify-content: flex-end;">
                                            <div class="newrow left-aligned"
                                                *ngIf="(choice.id+subFeatParameters.feat.name+'desc') as subItemID">
                                                <span class="gridicon-fullsizebox" style="flex-grow: 1;">
                                                    <input id="{{choice.id+subFeatParameters.feat.name}}"
                                                        type="checkbox" class="character-choice"
                                                        (change)="onFeatTaken(subFeatParameters.feat, $event, choice, false)"
                                                        [checked]="subFeatParameters.checked"
                                                        [disabled]="subFeatParameters.disabled"
                                                        *ngIf="subFeatParameters.available">
                                                    <span>
                                                        <strong>{{subFeatParameters.feat.subType}}</strong>
                                                        <span class="lower"
                                                            *ngIf="subFeatParameters.taken && subFeatParameters.taken.automatic">
                                                            Taken automatically
                                                        </span>
                                                    </span>
                                                </span>
                                                <button *ngIf="subFeatParameters.feat.canDelete"
                                                    [disabled]="subFeatParameters.checked"
                                                    (click)="removeObsoleteCustomFeat(subFeatParameters.feat)">
                                                    Delete
                                                </button>
                                                <button (click)="toggleShownSubFeat(subItemID)"
                                                    [ngClass]="{'fancy-button':shownSubFeat()===subItemID}">
                                                    {{shownSubFeat()===subItemID ? "Hide" :
                                                    "Show"}} Description
                                                </button>
                                                <div [ngbCollapse]="uncollapsedSubFeat()!==subItemID">
                                                    <div class="newrow"
                                                        *ngIf="shownSubFeat()===subItemID || uncollapsedSubFeat()!==subItemID">
                                                        <div class="button newrow no-animation"
                                                            *ngIf="subFeatParameters.available"
                                                            [ngClass]="{'fancy-button':subFeatParameters.taken, 'disabled':subFeatParameters.disabled}">
                                                            <label>
                                                                <input type="checkbox"
                                                                    (change)="onFeatTaken(subFeatParameters.feat, $event, choice, false)"
                                                                    [checked]="subFeatParameters.checked"
                                                                    [disabled]="subFeatParameters.disabled"
                                                                    hidden>{{subFeatParameters.checked
                                                                ?
                                                                "Remove" : "Choose"}}
                                                            </label>
                                                        </div>
                                                        <div class="newrow left-aligned">
                                                            <cite class="problem" [ngbPopover]="reason.explain"
                                                                *ngFor="let reason of subFeatParameters.cannotTake; trackBy:trackers.trackByIndex;">
                                                                {{reason.reason}}
                                                            </cite>
                                                        </div>
                                                        <app-feat class="newrow" [feat]="subFeatParameters.feat"
                                                            [choice]="choice" [levelNumber]="levelNumber"
                                                            [featLevel]="featLevel">
                                                        </app-feat>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-template>
                            <div class="list-item gridicon-fullsizebox"
                                [ngClass]="{'unavailable':(!featParameters.available), 'selected':(featParameters.taken || isAnySubFeatTakenByThisChoice(undefined, featParameters.feat, choice))}">
                                <input *ngIf="!featParameters.feat.subTypes" class="character-choice"
                                    id="{{featParameters.feat.name}}" type="checkbox"
                                    (change)="onFeatTaken(featParameters.feat, $event, choice, false)"
                                    [checked]="featParameters.checked"
                                    [disabled]="featParameters.disabled || !featParameters.available">
                                <button class="character-choice" (click)="FeatChoiceDetailPopover.toggle()"
                                    [ngClass]="{'fancy-button':featParameters.checked, 'inactive-button':(!featParameters.checked && !featParameters.available)}"
                                    *ngIf="featParameters.feat.subTypes">+</button>
                                <div class="gridicon-fullsizebox" #FeatChoiceDetailPopover="ngbPopover"
                                    [ngbPopover]="FeatChoiceDetailTemplate" triggers="click">
                                    <app-gridIcon [title]="featParameters.feat.displayName || featParameters.feat.name"
                                        [detail]="featParameters.feat.traits.includes('Rare') ? 'Rare' : (featParameters.feat.traits.includes('Uncommon') ? 'Uncommon' : '')">
                                    </app-gridIcon>
                                    <header class="sectionHeader">
                                        <span
                                            [ngbTooltip]="(!FeatChoiceDetailPopover.isOpen()) ? featParameters.feat.shortdesc : ''"
                                            triggers="hover" [openDelay]=100>
                                            {{["General", "Skill"].includes(choice.type) &&
                                            featParameters.feat.skillreq.length === 1 ? "(" +
                                            featParameters.feat.skillreq[0].skill + ") " :
                                            ""}}{{featParameters.feat.displayName || featParameters.feat.name}}
                                        </span>
                                        <span class="lower"
                                            *ngIf="featParameters.taken && featParameters.taken.automatic">
                                            Taken automatically
                                        </span>
                                        <cite *ngIf="featParameters.feat.levelreq">
                                            Level {{featParameters.feat.levelreq}}
                                        </cite>
                                        <ng-container
                                            *ngFor="let trait of ['Rare', 'Uncommon', 'Dedication']; trackBy:trackers.trackByIndex">
                                            <app-trait *ngIf="featParameters.feat.traits.includes(trait)" [name]="trait"
                                                [trait]="traitFromName(trait)">
                                            </app-trait>
                                        </ng-container>
                                    </header>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </ng-container>
</ng-container>
