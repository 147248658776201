<!--Adding Poisons-->
<ng-container *ngIf="availablePoisons() as availablePoisons">
    <div class="list-item" *ngIf="availablePoisons.length > 1 || item.poisonsApplied.length">
        <div class="newrow">
            <strong
                title="{{item.poisonsApplied.length ? 'Applying a new poison will waste the currently applied poison.' : ''}}">
                {{item.poisonsApplied.length ? 'Replace' : 'Apply'}} poison
            </strong>
            <select [(ngModel)]="newPoison" (ngModelChange)="onSelectPoison()">
                <option *ngFor="let poisonSet of availablePoisons; trackBy:trackers.trackByIndex;"
                    [ngValue]="poisonSet">
                    {{poisonSet.poison.amount !== 1 ? poisonSet.poison.amount : ''}}
                    {{poisonSet.poison.effectiveName()}}
                </option>
            </select>
        </div>
        <div class="newrow" *ngIf="item.poisonsApplied.length">
            <strong>Applied poison</strong>
            <div class="newrow"
                *ngFor="let poison of item.poisonsApplied; let index = index; trackBy:trackers.trackByIndex;">
                <span>{{poison.effectiveName()}}</span>
                <button class="lower" (click)="onRemovePoison(index)">Spend/Remove</button>
            </div>
        </div>
    </div>
</ng-container>
