export const AlwaysShowingEffectNames: Array<string> = [
    'AC',
    'Acrobatics',
    'Actions per Turn',
    'Agile Attack Rolls',
    'All Checks and DCs',
    'Arcana',
    'Athletics',
    'Attack Rolls',
    'Bulk',
    'Class DC',
    'Crafting',
    'Damage',
    'Damage Rolls',
    'Deception',
    'Diplomacy',
    'Expert Skill Checks',
    'Fortitude',
    'Hardness',
    'Intimidation',
    'Legendary Skill Checks',
    'Lore',
    'Master Skill Checks',
    'Max Dying',
    'Max HP',
    'Medicine',
    'Melee Attack Rolls',
    'Melee Damage',
    'Nature',
    'Non-Agile Attack Rolls',
    'Occultism',
    'Perception',
    'Performance',
    'Ranged Attack Rolls',
    'Ranged Damage',
    'Reach',
    'Reflex',
    'Religion',
    'Saving Throws',
    'Size',
    'Skill Checks',
    'Society',
    'Spell Attack Rolls',
    'Spell DCs',
    'Stealth',
    'Survival',
    'Thievery',
    'Trained Skill Checks',
    'Unarmed Attack Rolls',
    'Unarmed Damage',
    'Unarmed Damage per Die',
    'Untrained Skill Checks',
    'Weapon Damage per Die',
    'Will',
].map(name => name.toLowerCase());

export const AlwaysShowingWildcardEffectNames: Array<string> = [
    'Extra Damage',
    'Resistance',
    'Immunity',
    'Ignore',
    'Speed',
    '-based Checks and DCs',
    '-based Skill Checks',
    'Lore: ',
].map(name => name.toLowerCase());
