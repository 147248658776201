<!-- eslint-disable @angular-eslint/template/cyclomatic-complexity -->
<div class="newrow lower" *ngIf="spell">
    <div class="newrow left-aligned">
        <app-trait *ngFor="let trait of spell.traits; trackBy:trackers.trackByIndex;" [name]="trait"
            [trait]="traitFromName(trait)">
        </app-trait>
        <app-trait *ngFor="let trait of spell.activationTraits(); trackBy:trackers.trackByIndex;" [name]="trait"
            [trait]="traitFromName(trait)" [extraDescription]="'(This trait was derived from the action components.)'">
        </app-trait>
        <app-tags [creature]="creatureTypesEnum.Character" [objectName]="spell.name" [showTraits]=true [showFeats]=true
            [showItems]=true [showActivities]=true [showConditions]=true [showEffects]=true
            [specialNames]="source ? [source] : []"></app-tags>
    </div>
    <app-spellContent class="vlist fullwidth" [spell]="spell" [spellLevel]="spellLevelFromBaseLevel(spell, spellLevel)"
        [casting]="casting">
    </app-spellContent>
    <div class="newrow left-aligned"
        *ngFor="let feat of characterFeatsShowingHintsOnThis(spell.name); trackBy:trackers.trackByIndex;">
        <header class="spellHeader">{{feat.name}}</header>
        <div class="newrow left-aligned" *ngIf="feat.traits.length">
            <app-trait *ngFor="let trait of feat.traits; trackBy:trackers.trackByIndex;" [name]="trait"
                [trait]="traitFromName(trait)">
            </app-trait>
        </div>
        <div class="newrow left-aligned" *ngFor="let desc of feat.desc.split('\n\n'); trackBy:trackers.trackByIndex;">
            <app-description class="newrow" [text]="desc"></app-description>
        </div>
    </div>
</div>
