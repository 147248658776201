<!-- eslint-disable @angular-eslint/template/cyclomatic-complexity -->
<!--Changing Talismans-->

<div class="list-item">
    <div class="newrow" *ngFor="let index of availableSlots(); trackBy:trackers.trackByIndex;">
        <strong *ngIf="index === 0">Affix Talisman</strong>
        <span *ngIf="index !== 0">&nbsp;</span>
        <select [(ngModel)]="newTalisman[index]" (change)="onSelectTalisman(index)"
            [disabled]="!item.talismans[index-1] && index > 0">
            <ng-template #TalismanTemplate let-option>
                <option [ngValue]="option" title="{{talismanTitle(option.talisman, option.talismanCordCompatible)}}">
                    <span *ngIf="option.talismanCordCompatible">&#9741;</span>
                    {{option.talisman.amount > 1 ? option.talisman.amount : ""}}
                    {{option.talisman.name}}
                </option>
            </ng-template>
            <ng-container
                *ngFor="let talisman of initialTalismans(index); let initialTalismanIndex = index; trackBy:trackers.trackByIndex;">
                <option [ngValue]="talisman" *ngIf="initialTalismanIndex === 0">

                </option>
                <optgroup *ngIf="initialTalismanIndex === 1" label="Current Talisman">
                    <ng-container *ngTemplateOutlet="TalismanTemplate; context: {$implicit: talisman}"></ng-container>
                </optgroup>
            </ng-container>
            <ng-container *ngFor="let inv of inventoriesOrCleanItems(); trackBy:trackers.trackByIndex;">
                <ng-container *ngIf="availableTalismans(inv) as availableTalismans">
                    <optgroup label="{{itemStore ? 'Available talismans' : inventoryName(inv)}}"
                        *ngIf="availableTalismans.length">
                        <ng-container *ngFor="let talisman of availableTalismans; trackBy:trackers.trackByIndex;">
                            <ng-container *ngTemplateOutlet="TalismanTemplate; context: {$implicit: talisman}">
                            </ng-container>
                        </ng-container>
                    </optgroup>
                </ng-container>
            </ng-container>
        </select>
    </div>
</div>
