<!-- eslint-disable @angular-eslint/template/cyclomatic-complexity -->
<header class="sectionHeader" *ngIf="propertyData.priority === '00' && propertyData.group">
    {{propertyData.group.split("-")[1]}}</header>
<div class="list-item" *ngIf="parent() as parent">
    <ng-container *ngIf="!noTitle">
        <div class="newrow left-aligned">
            <strong [ngbPopover]="propertyData.desc">{{propertyData.name}}</strong>
        </div>
    </ng-container>
    <ng-container *ngIf="parent[propertyKey] as property">
        <ng-container *ngIf="property !== null && isPropertyAnArray(property)">
            <button (click)="addNewItemObject()">
                Add
            </button>
            <div class="list-item" *ngFor="let object of property; let i = index; trackBy:trackers.trackByIndex;">
                <span>
                    {{i+1}}
                </span>
                <span>
                    <button (click)="removeNewItemObject(i)">
                        Remove
                    </button>
                </span>
                <ng-container *ngIf="isPropertyAnObject(object); else LiteralArrayTemplate">
                    <ng-container *ngFor="let subProperty of subProperties(object); trackBy:trackers.trackByIndex;">
                        <app-newItemProperty [propertyData]=subProperty [propertyKey]=subProperty.key
                            [parents]=parents.concat(propertyKey,i.toString()) [newItem]=newItem>
                        </app-newItemProperty>
                    </ng-container>
                </ng-container>
                <ng-template #LiteralArrayTemplate>
                    <app-newItemProperty class="newrow" [propertyData]=propertyData [propertyKey]="indexToKey(i)"
                        [parents]=parents.concat(propertyKey) [noTitle]=true [newItem]=newItem>
                    </app-newItemProperty>
                </ng-template>
            </div>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="!isPropertyAnObject(parent[propertyKey]) || parent[propertyKey]===null">
        <div class="newrow">
            <input id="{{propertyKey}}" class="newrow" type="text"
                [(ngModel)]="objectPropertyAccessor(parent, propertyKey).value" (blur)="validate()"
                *ngIf="!propertyData.type && !propertyData.locked">
            <textarea class="newrow" rows=3 [(ngModel)]="objectPropertyAccessor(parent, propertyKey).value"
                *ngIf="propertyData.type==='textarea'">
            </textarea>
            <input type="checkbox" [(ngModel)]="objectPropertyAccessor(parent, propertyKey).value"
                *ngIf="propertyData.type==='checkbox'">
            <input type="number" [(ngModel)]="objectPropertyAccessor(parent, propertyKey).value" (blur)="validate()"
                *ngIf="propertyData.type==='number'">
        </div>
        <div class="newrow" *ngIf="validationError.length">
            <span class="problem">
                {{validationError}}
            </span>
        </div>
        <div class="newrow" *ngIf="validationResult.length">
            <span>
                Current result: {{validationResult}}
            </span>
        </div>
        <div class="list-item" style="margin: initial 0" *ngIf="propertyData.type!=='textarea' && propertyData.type!=='checkbox' &&
        !(propertyData.parent === 'gainItems' && propertyData.key === 'type')">
            <select class="newrow left-aligned" [(ngModel)]="objectPropertyAccessor(parent, propertyKey).value"
                [disabled]="!examples().length" (ngModelChange)="validate()">
                <option *ngFor="let example of examples(); trackBy:trackers.trackByIndex;" [ngValue]="example">
                    {{example}}
                </option>
            </select>
        </div>
        <div class="list-item" style="margin: initial 0"
            *ngIf="propertyData.parent === 'gainItems' && propertyData.key === 'type'">
            <select class="newrow left-aligned" [(ngModel)]="objectPropertyAccessor(parent, propertyKey).value"
                (ngModelChange)="setItemType()">
                <option *ngFor="let itemset of itemSets(); trackBy:trackers.trackByIndex;" [ngValue]="itemset.key">
                    {{itemset.name}}
                </option>
            </select>
        </div>
    </ng-container>
</div>
