<!-- eslint-disable @angular-eslint/template/cyclomatic-complexity -->
<div class="window-button-container">
    <button class="minimizebutton lower"
        [ngbTooltip]="isMinimized ? 'Click to show all information.' : 'Click to show compact information.'"
        [ngClass]="{'fancy-button':isMinimized}" (click)="minimize()" *ngIf="creature==='Character'">
        <i class='bi-arrows-collapse' *ngIf="isMinimized"></i>
        <i class='bi-arrows-expand' *ngIf="!isMinimized"></i>
    </button>
    <button class="tilemodebutton" [ngClass]="{'fancy-button':isTileMode}" (click)="toggleTileMode()"
        [ngbTooltip]="isTileMode ? 'Click to enable list layout' : 'Click to enable tiled layout'">
        <!--Content is css only-->
        <div>
            <div> </div>
            <div> </div>
            <div> </div>
            <div> </div>
        </div>
    </button>
</div>
<div id="{{creature}}-skills-height" class="attributeBox">
    <div class="loading" *ngIf="stillLoading">Loading</div>
    <ng-container *ngIf="!stillLoading">
        <header class="sectionHeader box-header">Perception</header>
        <ng-container *ngIf="ownedActivities() as activityGains">
            <ng-container *ngFor="let skill of skillsOfType('Perception'); trackBy:trackers.trackByIndex;">
                <app-skill [creature]="creature" [skill]=skill [minimized]="isMinimized"
                    [relatedActivityGains]="skillMatchingActivities(activityGains, skill.name)"
                    [showAction]="shownAction()" (showActionMessage)="receiveShowActionMessage($event)"></app-skill>
            </ng-container>
            <div class="vlist">
                <header class="subsectionHeader">Senses</header>
                <div class="list-item lower" [ngbPopover]="senseDesc(sense)"
                    *ngFor="let sense of senses(); trackBy:trackers.trackByIndex;">
                    {{sense}}
                </div>
            </div>
            <app-tags [creature]="creature" [objectName]="'Senses'" [showTraits]="true" [showFeats]="true"
                [showItems]="true" [showActivities]="true" [showConditions]="true"></app-tags>
            <ng-container *ngIf="creature==='Familiar'">
                <header class="sectionHeader box-header">Attack Rolls</header>
                <app-tags [creature]="creature" [objectName]="'Attacks'" [showTraits]="true" [showFeats]="true"
                    [showItems]="true" [showActivities]="true" [showConditions]="true"></app-tags>
                <ng-container
                    *ngFor="let skill of skillsOfType('Familiar Proficiency'); trackBy:trackers.trackByIndex;">
                    <app-skill [creature]="creature" [minimized]="isMinimized" [skill]=skill></app-skill>
                </ng-container>
            </ng-container>
            <ng-template #SpeedEffectsPopoverContent>
                <div class="fullsize-only newrow">
                    <app-objectEffects [creature]="creature" [objectName]="'Land Speed'"></app-objectEffects>
                </div>
                <div class="fullsize-only newrow">
                    <app-objectEffects [creature]="creature" [objectName]="'Burrow Speed'"></app-objectEffects>
                </div>
                <div class="fullsize-only newrow">
                    <app-objectEffects [creature]="creature" [objectName]="'Climb Speed'"></app-objectEffects>
                </div>
                <div class="fullsize-only newrow">
                    <app-objectEffects [creature]="creature" [objectName]="'Fly Speed'"></app-objectEffects>
                </div>
                <div class="fullsize-only newrow">
                    <app-objectEffects [creature]="creature" [objectName]="'Swim Speed'"></app-objectEffects>
                </div>
            </ng-template>
            <header class="sectionHeader box-header">
                <span class="fullsize-only" [ngbPopover]="SpeedEffectsPopoverContent" #SpeedEffectsPopover="ngbPopover"
                    triggers="click">
                    <i [ngbTooltip]="!SpeedEffectsPopover.isOpen() ? 'Edit effects' : ''"
                        class='bi-lightning-charge'></i>
                </span>
                Movement
            </header>
            <div class="list-item" *ngFor="let speedParameters of speedParameters(); trackBy:trackers.trackByIndex;">
                <div class="newrow">
                    <span>
                        <strong>{{(speedParameters.name==="Speed") ? "All Speeds" :
                            speedParameters.name}}</strong>
                    </span>
                    <span>
                        <div class="value" [ngbPopover]="speedParameters.value.explain" [ngClass]="{'penalty':speedParameters.showPenalties,
                        'bonus':speedParameters.showBonuses,
                        'absolute':speedParameters.absolutes.length}">
                            {{speedParameters.value.result}}
                        </div>
                    </span>
                </div>
                <app-tags [creature]="creature" [objectName]="speedParameters.name" [showTraits]="true"
                    [showFeats]="true" [showItems]="true" [showActivities]="true" [showConditions]="true"
                    [specialEffects]="speedParameters.absolutes.concat(speedParameters.relatives)">
                </app-tags>
            </div>
            <app-tags [creature]="creature" [objectName]="'Movement'" [showTraits]="true" [showFeats]="true"
                [showItems]="true" [showActivities]="true" [showConditions]="true" [showEffects]="true"></app-tags>
            <header class="sectionHeader box-header">Skills</header>
            <!--Skill Increases-->
            <ng-container *ngFor="let choice of skillChoices(); trackBy:trackers.trackByIndex;">
                <app-skillchoice (showSkillChoiceMessage)="receiveShowChoiceMessage($event)" [showTitle]="true"
                    [showContent]="true" [choice]="choice" [showChoice]="shownList()">
                </app-skillchoice>
            </ng-container>
            <app-tags [creature]="creature" [objectName]="'Skills'" [showTraits]="true" [showFeats]="true"
                [showItems]="true" [showActivities]="true" [showConditions]="true" [showEffects]="true"></app-tags>
            <app-tags [creature]="creature" [objectName]="'Skill Checks'" [showTraits]="true" [showFeats]="true"
                [showItems]="true" [showActivities]="true" [showConditions]="true" [showEffects]="true"></app-tags>
            <ng-container *ngFor="let skill of skillsOfType('Skill'); trackBy:trackers.trackByIndex;">
                <app-skill [creature]="creature" [skill]=skill [minimized]="isMinimized"
                    [relatedActivityGains]="skillMatchingActivities(activityGains, skill.name)"
                    [showAction]="shownAction()" (showActionMessage)="receiveShowActionMessage($event)"></app-skill>
            </ng-container>
        </ng-container>
    </ng-container>
</div>
