<!-- eslint-disable @angular-eslint/template/cyclomatic-complexity -->
<!--Changing Talisman Cords-->
<div class="list-item">
    <strong>Attach Talisman Cord</strong>
    <select [(ngModel)]="newTalismanCord" (change)="onSelectTalismanCord()">
        <ng-container
            *ngFor="let cord of initialTalismanCords(); let initialCordIndex = index; trackBy:trackers.trackByIndex;">
            <option [ngValue]="cord" *ngIf="initialCordIndex === 0">
            </option>
            <optgroup *ngIf="initialCordIndex === 1" label="Current cord">
                <option [ngValue]="cord">
                    {{cord.talismanCord.name}}
                    ({{talismanCordSchools(cord.talismanCord)}})
                </option>
            </optgroup>
        </ng-container>
        <ng-container *ngFor="let inv of inventories(); trackBy:trackers.trackByIndex;">
            <ng-container *ngIf="availableTalismanCords(inv) as availableTalismanCords">
                <optgroup label="{{inventoryName(inv)}}" *ngIf="availableTalismanCords.length">
                    <option *ngFor="let cordSet of availableTalismanCords; trackBy:trackers.trackByIndex;"
                        [ngValue]="cordSet">
                        {{cordSet.talismanCord.amount > 1 ? cordSet.talismanCord.amount : ""}}
                        {{cordSet.talismanCord.name}}
                        ({{talismanCordSchools(cordSet.talismanCord)}})
                    </option>
                </optgroup>
            </ng-container>
        </ng-container>
    </select>
</div>
