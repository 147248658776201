<!-- eslint-disable @angular-eslint/template/cyclomatic-complexity -->
<div class="window-button-container">
    <button class="tilemodebutton" [ngClass]="{'fancy-button':isTileMode}" (click)="toggleTileMode()"
        [ngbTooltip]="isTileMode ? 'Click to enable list layout' : 'Click to enable tiled layout'">
        <!--Content is css only-->
        <div>
            <div> </div>
            <div> </div>
            <div> </div>
            <div> </div>
        </div>
    </button>
</div>
<div id="spelllibrary" class="itembox vlist">
    <button class="itembox-close-button list-item center-aligned" (click)="toggleSpellLibraryMenu()">
        <header class="sectionHeader">Back to Character Sheet</header>
    </button>
    <div class="loading" *ngIf="stillLoading">Loading</div>
    <ng-container *ngIf="spellLibraryMenuState==='in'">
        <div class="charactersheet-column-container">
            <div class="charactersheet-column">
                <div class="list-item">
                    <strong>Find (in Name, Description or Traits)</strong>
                    <span class="hlist">
                        <input id="SpellsWordFilter" type=text [(ngModel)]="wordFilter"
                            (keypress)="closeFilterIfTooShort()" />
                        <button (click)="setFilterForAll()" [disabled]="wordFilter.length < 5">Show All</button>
                        <button (click)="wordFilter=''; closeFilterIfTooShort()">Clear</button>
                    </span>
                </div>
                <div class="list-item">
                    <strong>Show spells from</strong>
                    <select [(ngModel)]="spellSource">
                        <option
                            *ngFor="let source of ['spell library','your spellbook']; trackBy:trackers.trackByIndex;"
                            [ngValue]="source">
                            {{source}}
                        </option>
                    </select>
                </div>
                <div class="list-item">
                    <strong>
                        Show spells on level
                    </strong>
                    <span>
                        <select [(ngModel)]="showLevel">
                            <option *ngFor="let levelOption of [0,1,2,3,4,5,6,7,8,9,10]; trackBy:trackers.trackByIndex;"
                                [ngValue]="levelOption">
                                {{levelOption === 0 ? "Default" : levelOption}}
                            </option>
                        </select>
                    </span>
                </div>
                <div class="list-item">
                    <button (click)="toggleTraditionFilter('')"
                        [ngClass]="{'fancy-button':(shownTraditionFilter()==='')}">
                        All except Focus
                    </button>
                    <button *ngFor="let tradition of spellTraditions; trackBy:trackers.trackByIndex"
                        (click)="toggleTraditionFilter(tradition)"
                        [ngClass]="{'fancy-button':(shownTraditionFilter()===tradition)}">
                        {{tradition}}
                    </button>
                </div>
                <div class="fullsize-scroll-box vlist">
                    <ng-container *ngIf="componentParameters() as componentParameters">
                        <div class="list-item"
                            *ngIf="availableForLearningDescription(componentParameters.wizardCasting, componentParameters.bardCasting, componentParameters.sorcererCasting) as availableForLearningDescription">
                            <app-description class="newrow"
                                *ngFor="let desc of availableForLearningDescription.split('\n\n'); trackBy:trackers.trackByIndex;"
                                [text]="desc"></app-description>
                        </div>
                        <div class="list-item"
                            *ngIf="spellMasteryAvailableDescription(componentParameters.wizardCasting) as spellMasteryAvailableDescription">
                            <app-description class="newrow"
                                *ngFor="let desc of spellMasteryAvailableDescription.split('\n\n'); trackBy:trackers.trackByIndex;"
                                [text]="desc"></app-description>
                        </div>
                        <ng-container
                            *ngFor="let level of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]; trackBy:trackers.trackByIndex;">
                            <ng-container *ngIf="visibleSpellsOfLevel(level) as visibleSpells">
                                <button class="list-item" (click)="toggleShownList(level)"
                                    [ngClass]="{'fancy-button':[-2,level].includes(shownList())}"
                                    *ngIf="visibleSpells.length">
                                    <header class="sectionHeader" *ngIf="level === 0">Cantrips</header>
                                    <header class="sectionHeader" *ngIf="level > 0">{{"Level "+level}}</header>
                                </button>
                                <div [ngClass]="{'icon-list left-aligned':isTileMode, 'list-item':!isTileMode}"
                                    *ngIf="visibleSpells.length && [-2,level].includes(shownList())">
                                    <div class="newrow list-item" *ngIf="visibleSpells.length >= 80 && shownList()!==-2"
                                        (click)="incRange(-1)">
                                        <button class="center-aligned" [disabled]="range <= 0">
                                            Previous 40
                                        </button>
                                        <header class="newrow subsectionHeader center-aligned">
                                            {{shownItemRangeDesc(visibleSpells, range)}}
                                        </header>
                                    </div>
                                    <ng-container
                                        *ngFor="let spell of visibleSpells; let spellIndex = index; trackBy:trackers.trackByIndex;">
                                        <ng-container *ngIf="isSpellShown(visibleSpells, spellIndex, range)">
                                            <ng-template #SpellTemplate>
                                                <header class="spellHeader" *ngIf="isTileMode">
                                                    {{spell.name}}
                                                    <app-actionIcons *ngIf="spell.actions"
                                                        [actionString]="spell.actions">
                                                    </app-actionIcons>
                                                    {{spell.castType}}
                                                </header>
                                                <button class="newRow center-aligned"
                                                    *ngFor="let learningOption of availableSpellLearningOptions(spell, level, componentParameters.wizardCasting, componentParameters.bardCasting, componentParameters.sorcererCasting); trackBy:trackers.trackByIndex"
                                                    (click)="learnSpell(spell, learningOption.key)"
                                                    [disabled]="learningOption.disabled">
                                                    {{learningOption.title}}
                                                </button>
                                                <button class="newrow center-aligned" (click)="unlearnSpell(spell)"
                                                    [disabled]="" *ngIf="isSpellLearned(spell.name) as learned">
                                                    Unlearn {{learnedSpellSource(learned.source)}}
                                                </button>
                                                <ng-container
                                                    *ngIf="componentParameters.wizardCasting as wizardCasting">
                                                    <button class="newrow center-aligned"
                                                        (click)="addSpellToSpellMastery(spell)"
                                                        [disabled]="!isSpellMasteryAllowedForSpell(wizardCasting, level, spell)"
                                                        *ngIf="canSpellBeSelectedForSpellMastery(wizardCasting, spell)">
                                                        <i class='ra ra-crown'></i>
                                                        Add to Spell Mastery selection
                                                    </button>
                                                    <button class="newrow center-aligned"
                                                        (click)="removeSpellFromSpellMastery(wizardCasting, spell)"
                                                        *ngIf="isSpellSelectedForSpellMastery(wizardCasting, spell)">
                                                        <i class='ra ra-crown'></i>
                                                        Remove from Spell Mastery selection
                                                    </button>
                                                </ng-container>
                                                <div class="list-item">
                                                    <strong>
                                                        Show spell on level
                                                    </strong>
                                                    <span>
                                                        <select [(ngModel)]="showLevel">
                                                            <option
                                                                *ngFor="let levelOption of [0,1,2,3,4,5,6,7,8,9,10]; trackBy:trackers.trackByIndex;"
                                                                [disabled]="levelOption && spell.levelreq > levelOption"
                                                                [ngValue]="levelOption">
                                                                {{levelOption === 0 ? "Default (" +
                                                                spell.levelreq + ")" : levelOption}}
                                                            </option>
                                                        </select>
                                                    </span>
                                                </div>
                                                <app-spell [spell]="spell" [spellLevel]="showLevel ? showLevel : level">
                                                </app-spell>
                                            </ng-template>
                                            <ng-template #SpellTitleTemplate>
                                                <span *ngIf="!isTileMode">
                                                    <i class="value bi-patch-plus bonus" *ngIf="!spell.isHostile()"
                                                        [ngbTooltip]="'Beneficial spell'"></i>
                                                    <i class="value bi-patch-minus-fill penalty"
                                                        *ngIf="spell.isHostile()" [ngbTooltip]="'Hostile spell'"></i>
                                                </span>
                                                <span>
                                                    {{spell.name}}
                                                    <app-actionIcons *ngIf="spell.actions"
                                                        [actionString]="spell.actions">
                                                    </app-actionIcons>
                                                    {{spell.castType}}
                                                </span>
                                            </ng-template>
                                            <ng-container *ngIf="!isTileMode">
                                                <div class="list-item">
                                                    <button class="newrow left-aligned sublist-toggle"
                                                        (click)="toggleShownItem(spell.id)"
                                                        [ngClass]="{'fancy-button choicecleared':isSpellLearned(spell.name)}">
                                                        <span [ngbTooltip]="'Spell Mastery spell'"
                                                            *ngIf="isSpellSelectedForSpellMastery(componentParameters.wizardCasting, spell)"><i
                                                                class='ra ra-crown'></i>
                                                        </span>
                                                        <span>
                                                            <ng-container *ngTemplateOutlet="SpellTitleTemplate">
                                                            </ng-container>
                                                            <ng-container
                                                                *ngFor="let trait of ['Rare', 'Uncommon']; trackBy:trackers.trackByIndex">
                                                                <app-trait *ngIf="spell.traits.includes(trait)"
                                                                    [name]="trait" [trait]="traitFromName(trait)">
                                                                </app-trait>
                                                            </ng-container>
                                                        </span>
                                                    </button>
                                                    <div class="list-item sublist" *ngIf="shownItem()===spell.id"
                                                        [ngClass]="{'fancy-list':isSpellLearned(spell.name)}">
                                                        <ng-container *ngTemplateOutlet="SpellTemplate">
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="isTileMode">
                                                <button
                                                    [ngClass]="{'fancy-button choicecleared':isSpellLearned(spell.name), 'penalty':spell.isHostile(), 'bonus':!spell.isHostile()}"
                                                    [ngbPopover]="SpellTemplate" triggers="click"
                                                    (click)="toggleShownItem()">
                                                    <app-gridIcon
                                                        [ngClass]="{'fancy-button':isSpellLearned(spell.name), 'penalty':spell.isHostile(), 'bonus':!spell.isHostile()}"
                                                        [ngbTooltip]="SpellTitleTemplate" [title]="spell.name"
                                                        [detail]="spell.traits.includes('Rare') ? 'R' : (spell.traits.includes('Uncommon') ? 'U' : '')"
                                                        [superTitle]="isSpellSelectedForSpellMastery(componentParameters.wizardCasting, spell) ? 'icon-ra ra-crown' : ''"
                                                        [spell]="spell">
                                                    </app-gridIcon>
                                                </button>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                    <div class="newrow list-item"
                                        *ngIf="visibleSpells.length >= 80 && shownList()!==-2">
                                        <button class="center-aligned"
                                            [disabled]="(range + 1) * 40 >= visibleSpells.length" (click)="incRange(1)">
                                            Next 40
                                        </button>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
            <div class="charactersheet-column mobile-hide">
                <app-spellbook [ngClass]="{'minimized':isSpellbookMinimized}">
                </app-spellbook>
            </div>
        </div>
    </ng-container>
</div>
