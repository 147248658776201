<!-- eslint-disable @angular-eslint/template/cyclomatic-complexity -->
<button class="minimizebutton lower"
    [ngbTooltip]="isMinimized ? 'Click to show all information.' : 'Click to show compact information.'"
    [ngClass]="{'fancy-button':isMinimized}" (click)="minimize()" *ngIf="creature==='Character'">
    <i class='bi-arrows-collapse' *ngIf="isMinimized"></i>
    <i class='bi-arrows-expand' *ngIf="!isMinimized"></i>
</button>
<div id="{{creature}}-abilities-height" class="attributeBox">
    <header class="sectionHeader box-header">Abilities</header>
    <ng-template #LoadingTemplate>
        <div class="loading">Loading</div>
    </ng-template>
    <ng-container *ngIf="!stillLoading; else LoadingTemplate">
        <ng-container *ngIf="!isMinimized">
            <div class="list-item" *ngFor="let ability of abilities(); trackBy:trackers.trackByIndex;">
                <ng-container *ngIf="calculateAbility(ability) as calculatedAbility">
                    <ng-template #EffectsPopoverContent>
                        <div class="fullsize-only newrow">
                            <app-objectEffects [creature]="creature" [objectName]="ability.name"></app-objectEffects>
                        </div>
                    </ng-template>
                    <div class="newrow">
                        <span class="hlist">
                            <span class="fullsize-only" [ngbPopover]="EffectsPopoverContent"
                                #EffectsPopover="ngbPopover" triggers="click">
                                <i [ngbTooltip]="!EffectsPopover.isOpen() ? 'Edit effects' : ''"
                                    class='bi-lightning-charge'></i>
                            </span>
                            <strong>{{ability.name}}</strong>
                        </span>
                        <span>
                            <div class="value" [ngbPopover]="calculatedAbility.value.explain"
                                [ngClass]="{'penalty':calculatedAbility.penalties, 'bonus':calculatedAbility.bonuses, 'absolute':calculatedAbility.absolutes}">
                                {{calculatedAbility.value.result}}
                            </div>
                        </span>
                        <strong>Modifier</strong>
                        <span>
                            <div class="value" [ngbPopover]="calculatedAbility.mod.explain"
                                [ngClass]="{'penalty':calculatedAbility.modpenalties, 'bonus':calculatedAbility.modbonuses, 'absolute':calculatedAbility.modabsolutes}">
                                {{calculatedAbility.mod.result}}
                            </div>
                        </span>
                    </div>
                    <app-tags class="newrow tags" [creature]="creature" [objectName]=ability.name [showTraits]=true
                        [showFeats]=true [showItems]=true [showActivities]=true [showConditions]=true
                        [showEffects]=true>
                    </app-tags>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="isMinimized">
            <div class="list-item newrow" *ngFor="let subset of [1,2]; trackBy:trackers.trackByIndex;">
                <span class="hlist"
                    [ngClass]="{'left-aligned':index === 0, 'center-aligned':index === 1, 'right-aligned':index === 2}"
                    *ngFor="let ability of abilities(subset); let index = index; trackBy:trackers.trackByIndex;">
                    <ng-container *ngIf="calculateAbility(ability) as calculatedAbility">
                        <strong>{{ability.modifierName}}</strong>
                        <div class="value" [ngbPopover]="calculatedAbility.mod.explain"
                            [ngClass]="{'penalty':calculatedAbility.modpenalties, 'bonus':calculatedAbility.modbonuses, 'absolute':calculatedAbility.modabsolutes}">
                            {{calculatedAbility.mod.result}}
                        </div>
                    </ng-container>
                </span>
            </div>
        </ng-container>
    </ng-container>
</div>
