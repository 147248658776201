<!-- eslint-disable @angular-eslint/template/cyclomatic-complexity -->
<app-toast-container class="ngb-toasts" aria-live="polite" aria-atomic="true"></app-toast-container>
<div id="charactersheet" class="charactersheet" *ngIf="!stillLoading">
    <!--Desktop-only views-->
    <div class="charactersheet-app charactersheet-row mobile-hide" id="desktop-tabs" *ngIf="!mobile">
        <button
            [ngClass]="{'fancy-button':shownMode()==='' || !['Encounter','Exploration','Downtime','Inventory','ConditionsEffects'].includes(shownMode())}"
            (click)="toggleShownMode('')">Show All</button>
        <button [ngClass]="{'fancy-button':shownMode()==='Encounter'}"
            (click)="toggleShownMode('Encounter')">Encounter</button>
        <button [ngClass]="{'fancy-button':shownMode()==='Exploration'}"
            (click)="toggleShownMode('Exploration')">Exploration</button>
        <button [ngClass]="{'fancy-button':shownMode()==='Downtime'}"
            (click)="toggleShownMode('Downtime')">Downtime</button>
        <button [ngClass]="{'fancy-button':shownMode()==='Inventory'}"
            (click)="toggleShownMode('Inventory')">Inventory</button>
        <button [ngClass]="{'fancy-button':shownMode()==='ConditionsEffects'}"
            (click)="toggleShownMode('ConditionsEffects')">Conditions & Effects</button>
    </div>
    <ng-container *ngIf="!stillLoading && shownMode() !== 'ConditionsEffects' && !mobile">
        <app-time class="charactersheet-app charactersheet-row mobile-hide"
            [showTurn]="(['','Encounter'].includes(shownMode()))" [showTime]="(shownMode() !== 'Encounter')"
            [ngClass]="{'minimized':timeMinimized}"></app-time>
        <app-effects id="Character-effects" class="charactersheet-app charactersheet-row mobile-hide">
        </app-effects>
        <div class="charactersheet-column-container mobile-hide">
            <div class="charactersheet-column">
                <app-general id="Character-general" class="charactersheet-app"
                    [ngClass]="{'minimized':generalMinimized}"
                    *ngIf="!['Encounter', 'Inventory'].includes(shownMode())">
                </app-general>
                <app-defense id="Character-defense" class="charactersheet-app"
                    [ngClass]="{'minimized':defenseMinimized}" *ngIf="shownMode()==='Encounter'">
                </app-defense>
                <app-health id="Character-health" class="charactersheet-app"
                    [showMinimizeButton]="(shownMode() !== 'Downtime')"
                    [ngClass]="{'minimized':(healthMinimized || (shownMode() === 'Downtime'))}"
                    *ngIf="shownMode()!=='Inventory'">
                </app-health>
                <app-general id="Character-general" class="charactersheet-app"
                    [ngClass]="{'minimized':generalMinimized}" *ngIf="(shownMode() === 'Encounter')">
                </app-general>
                <app-abilities id="Character-abilities" class="charactersheet-app"
                    [ngClass]="{'minimized':abilitiesMinimized}" *ngIf="shownMode()!=='Inventory'">
                </app-abilities>
                <app-skills id="Character-skills" class="charactersheet-app" [ngClass]="{'minimized':skillsMinimized}"
                    *ngIf="['', 'Encounter'].includes(shownMode()) || !['Exploration','Downtime','Inventory'].includes(shownMode())">
                </app-skills>
                <app-activities id="Character-activities" class="charactersheet-app"
                    [ngClass]="{'minimized':activitiesMinimized}"
                    *ngIf="['', 'Encounter'].includes(shownMode()) || !['Exploration','Downtime','Inventory'].includes(shownMode())">
                </app-activities>
                <app-spellbook id="spellbook" class="charactersheet-app" [ngClass]="{'minimized':spellbookMinimized}"
                    *ngIf="(shownMode() === 'Downtime')">
                </app-spellbook>
                <app-inventory id="Character-inventory" class="charactersheet-app"
                    [ngClass]="{'minimized':inventoryMinimized}" *ngIf="shownMode()==='Inventory'">
                </app-inventory>
            </div>
            <div class="charactersheet-column">
                <app-defense id="Character-defense" class="charactersheet-app"
                    [ngClass]="{'minimized':defenseMinimized}" *ngIf="['', 'Inventory'].includes(shownMode())">
                </app-defense>
                <ng-container *ngFor="let index of [0,1]; trackBy:trackers.trackByIndex;">
                    <app-attacks id="Character-attacks" class="charactersheet-app"
                        [ngClass]="{'minimized':attacksMinimized}"
                        *ngIf="(['','Encounter','Inventory'].includes(shownMode()) || !['Exploration','Downtime'].includes(shownMode())) && attacksAndSpellsOrder('attacks') === index">
                    </app-attacks>
                    <app-spellbook id="spellbook" class="charactersheet-app"
                        [ngClass]="{'minimized':spellbookMinimized}"
                        *ngIf="(['','Encounter'].includes(shownMode()) || !['Exploration','Downtime','Inventory'].includes(shownMode())) && attacksAndSpellsOrder('spells') === index">
                    </app-spellbook>
                </ng-container>
                <app-skills id="Character-skills" class="charactersheet-app" [ngClass]="{'minimized':skillsMinimized}"
                    *ngIf="['Exploration','Downtime','Inventory'].includes(shownMode())"></app-skills>
                <app-activities id="Character-activities" class="charactersheet-app"
                    [ngClass]="{'minimized':activitiesMinimized}"
                    *ngIf="['Exploration','Downtime'].includes(shownMode())">
                </app-activities>
                <app-spellbook id="spellbook" class="charactersheet-app" [ngClass]="{'minimized':spellbookMinimized}"
                    *ngIf="(shownMode() === 'Exploration')">
                </app-spellbook>
                <app-inventory id="Character-inventory" class="charactersheet-app"
                    [ngClass]="{'minimized':inventoryMinimized}" *ngIf="shownMode()===''"></app-inventory>
            </div>
        </div>
    </ng-container>
    <!--Mobile-Only buttons and views-->
    <div class="charactersheet-app charactersheet-row desktop-hide" id="mobile-tabs" *ngIf="mobile">
        <button
            [ngClass]="{'fancy-button':shownMode()==='' || !['Health','Defense','Attacks','Spells','Skills','Actions','Inventory','ConditionsEffects'].includes(shownMode())}"
            (click)="toggleShownMode('')">
            <i class="bi-info-circle-fill"></i>
        </button>
        <button [ngClass]="{'fancy-button':shownMode()==='Health'}" (click)="toggleShownMode('Health')">
            <i class="ra ra-health"></i>
        </button>
        <button [ngClass]="{'fancy-button':shownMode()==='Defense'}" (click)="toggleShownMode('Defense')">
            <i class="ra ra-shield"></i>
        </button>
        <button [ngClass]="{'fancy-button':shownMode()==='Attacks'}" (click)="toggleShownMode('Attacks')">
            <i class="ra ra-sword"></i>
        </button>
        <button [ngClass]="{'fancy-button':shownMode()==='Spells'}" (click)="toggleShownMode('Spells')">
            <i class="ra ra-doubled"></i>
        </button>
        <button [ngClass]="{'fancy-button':shownMode()==='Skills'}" (click)="toggleShownMode('Skills')">
            <i class="ra ra-muscle-up"></i>
        </button>
        <button [ngClass]="{'fancy-button':shownMode()==='Actions'}" (click)="toggleShownMode('Actions')">
            <app-actionIcons [actionString]="'1A'" style="margin:.12rem -.16rem -.14rem -.16rem"></app-actionIcons>
        </button>
        <button [ngClass]="{'fancy-button':shownMode()==='Inventory'}" (click)="toggleShownMode('Inventory')">
            <i class="bi-box"></i>
        </button>
        <button [ngClass]="{'fancy-button':shownMode()==='ConditionsEffects'}"
            (click)="toggleShownMode('ConditionsEffects')">
            <i class="ra ra-lightning-bolt"></i>
            <i class="bi-clock"></i>
        </button>
    </div>
    <ng-container *ngIf="!stillLoading && shownMode() !== 'ConditionsEffects' && mobile">
        <div class="charactersheet-column-container desktop-hide">
            <div class="charactersheet-column">
                <app-general id="Character-general" class="charactersheet-app"
                    [ngClass]="{'minimized':generalMinimized}"
                    *ngIf="!shownMode() || !['Health','Defense','Attacks','Spells','Skills','Actions','Inventory','ConditionsEffects'].includes(shownMode())">
                </app-general>
                <app-abilities id="Character-abilities" class="charactersheet-app"
                    [ngClass]="{'minimized':abilitiesMinimized}" *ngIf="!shownMode()">
                </app-abilities>
                <app-health id="Character-health" class="charactersheet-app"
                    [showMinimizeButton]="(shownMode() !== 'Downtime')"
                    [ngClass]="{'minimized':(healthMinimized || (shownMode() === 'Downtime'))}"
                    *ngIf="shownMode()==='Health'">
                </app-health>
                <app-defense id="Character-defense" class="charactersheet-app"
                    [ngClass]="{'minimized':defenseMinimized}" *ngIf="shownMode()==='Defense'">
                </app-defense>
                <app-attacks id="Character-attacks" class="charactersheet-app"
                    [ngClass]="{'minimized':attacksMinimized}" *ngIf="shownMode()==='Attacks'">
                </app-attacks>
                <app-spellbook id="spellbook" class="charactersheet-app" [ngClass]="{'minimized':spellbookMinimized}"
                    *ngIf="shownMode()==='Spells'">
                </app-spellbook>
                <app-skills id="Character-skills" class="charactersheet-app" [ngClass]="{'minimized':skillsMinimized}"
                    *ngIf="shownMode()==='Skills'">
                </app-skills>
                <app-activities id="Character-activities" class="charactersheet-app"
                    [ngClass]="{'minimized':activitiesMinimized}" *ngIf="shownMode()==='Actions'">
                </app-activities>
                <app-inventory id="Character-inventory" class="charactersheet-app"
                    [ngClass]="{'minimized':inventoryMinimized}" *ngIf="shownMode()==='Inventory'">
                </app-inventory>
            </div>
        </div>
    </ng-container>
    <!--Conditions and Effects are the same on mobile and desktop-->
    <ng-container *ngIf="(shownMode() === 'ConditionsEffects')">
        <app-time class="charactersheet-app charactersheet-row" [ngClass]="{'minimized':timeMinimized}"></app-time>
        <div class="charactersheet-column charactersheet-row">
            <app-effects id="Character-effects" class="charactersheet-app" [fullDisplay]="true">
            </app-effects>
        </div>
    </ng-container>
</div>
<!--Slide-in menus-->
<app-items [@slideInOut]="itemsMenuState" *ngIf="!stillLoading">
</app-items>
<app-crafting [@slideInOut]="craftingMenuState" *ngIf="!stillLoading">
</app-crafting>
<app-spells [ngClass]="{'minimized':spellsMinimized}" [@slideInOut]="spellsMenuState" *ngIf="!stillLoading">
</app-spells>
<app-spellLibrary [@slideInOut]="spellLibraryMenuState" *ngIf="!stillLoading"></app-spellLibrary>
<app-conditions [@slideInOut]="conditionsMenuState" *ngIf="!stillLoading"></app-conditions>
<app-character [ngClass]="{'minimized':characterMinimized}" [@slideInOut]="characterMenuState" *ngIf="!stillLoading">
</app-character>
<app-animal-companion [ngClass]="{'minimized':companionMinimized}" [@slideInOut]="companionMenuState"
    *ngIf="!stillLoading && companionAvailable()">
</app-animal-companion>
<app-familiar [ngClass]="{'minimized':familiarMinimized}" [@slideInOut]="familiarMenuState"
    *ngIf="!stillLoading && familiarAvailable()"></app-familiar>
<app-dice [@slideInOutVert]="diceMenuState"></app-dice>
