<!-- eslint-disable @angular-eslint/template/cyclomatic-complexity -->
<ng-container *ngIf="itemRoles() as itemRoles">
    <div class="newrow left-aligned" *ngIf="item.sourceBook">
        <span>
            <strong>Source</strong>
            <i> {{item.sourceBook}}</i>
        </span>
    </div>
    <div class="newrow left-aligned" *ngIf="item.PFSnote">
        <span>
            <strong>PFS Note</strong>
            <i> {{item.PFSnote}}</i>
        </span>
    </div>
    <div class="newrow left-aligned">
        <span *ngIf="item.tradeable && priceText() as calculatedPriceText">
            <strong>Price</strong>
            {{calculatedPriceText}}
            {{itemRoles.stack && itemRoles.stack > 1 ? "per "+itemRoles.stack : ""}}
        </span>
        <span *ngIf="itemRoles.asWeapon?.dicenum || itemRoles.asWeapon?.extraDamage">
            <strong>Damage</strong>
            <ng-container *ngIf="itemRoles.asWeapon?.dicenum">
                {{itemRoles.asWeapon?.dicenum}}d{{itemRoles.asWeapon?.dicesize}}
                {{itemRoles.asWeapon?.dmgType}}
            </ng-container>
            <ng-container *ngIf="itemRoles.asWeapon?.extraDamage">
                {{itemRoles.asWeapon?.extraDamage?.replace('\n', ' ')}}
            </ng-container>
        </span>
        <span *ngIf="acBonusParameters(itemRoles) as acBonusParameters">
            <strong>AC Bonus</strong>
            <span [ngClass]="{'penalty':acBonusParameters.penalty, 'bonus':acBonusParameters.bonus}">
                {{acBonusParameters.effective}}
                {{itemRoles.asShield?.coverbonus ? "(4)" : ""}}
            </span>
        </span>
        <span *ngIf="dexCapParameters(itemRoles) as dexCapParameters">
            <strong>Dex Cap</strong>
            <span [ngClass]="{'penalty':dexCapParameters.penalty, 'bonus':dexCapParameters.bonus}">
                {{dexCapParameters.effective < 0 ? 'none' : dexCapParameters.effective}} <!--this comment prevents
                    strange formatting-->
            </span>
        </span>
        <span *ngIf="skillPenaltyParameters(itemRoles) as skillPenaltyParameters">
            <strong>Check Penalty</strong>
            <span [ngClass]="{'penalty':skillPenaltyParameters.penalty, 'bonus':skillPenaltyParameters.bonus}">
                {{skillPenaltyParameters.effective}}
            </span>
        </span>
        <span *ngIf="speedPenaltyParameters(itemRoles) as speedPenaltyParameters">
            <strong>Speed Penalty</strong>
            <span [ngClass]="{'penalty':speedPenaltyParameters.penalty, 'bonus':speedPenaltyParameters.bonus}">
                {{speedPenaltyParameters.effective}}
            </span>
        </span>
        <span *ngIf="strengthRequirementParameters(itemRoles) as strenthRequirementParameters">
            <strong>Strength</strong>
            <span
                [ngClass]="{'penalty':strenthRequirementParameters.penalty, 'bonus':strenthRequirementParameters.bonus}">
                {{strenthRequirementParameters.effective}}
            </span>
        </span>
        <span *ngIf="uncertainItemLiteralValue('usage') as usage">
            <strong>Usage</strong>
            {{usage}}
        </span>
        <span *ngIf="item.storeBulk">
            <strong>Bulk</strong>
            {{item.storeBulk}}
        </span>
        <span *ngIf="bulkParameters(item) as bulkParameters">
            <strong>
                Bulk
                {{(itemRoles.asEquipment?.carryingBulk && !itemRoles.asEquipment?.equipped) ? "(unequipped)" :
                ""}}
            </strong>
            <span [ngClass]="{'penalty':bulkParameters.penalty, 'bonus':bulkParameters.bonus}">
                {{bulkParameters.effective || 0}}
            </span>
        </span>
        <span *ngIf="itemRoles.asEquipment?.carryingBulk && itemRoles.asEquipment?.equipped">
            <strong>Unequipped bulk</strong>
            {{itemRoles.asEquipment?.carryingBulk}}
        </span>
        <span *ngIf="hardnessParameters(itemRoles) as hardnessParameters">
            <strong>Hardness</strong>
            <span [ngClass]="{'penalty':hardnessParameters.penalty, 'bonus':hardnessParameters.bonus}">
                {{hardnessParameters.effective}}
            </span>
        </span>
        <span *ngIf="hitpointParameters(itemRoles) as hitpointParameters">
            <strong>HP</strong><strong *ngIf="hitpointParameters.brokenThreshold"> (BT)</strong>
            <span [ngClass]="{'penalty':hitpointParameters.maxHP.penalty, 'bonus':hitpointParameters.maxHP.bonus}">
                {{hitpointParameters.maxHP.effective}}
            </span>
            <span *ngIf="hitpointParameters.brokenThreshold"
                [ngClass]="{'penalty':hitpointParameters.brokenThreshold.penalty, 'bonus':hitpointParameters.brokenThreshold.bonus}">
                ({{hitpointParameters.brokenThreshold.effective}})
            </span>
        </span>
    </div>
    <div class="newrow left-aligned" *ngIf="itemRoles.asWeapon || uncertainItemLiteralValue('hands')">
        <span *ngIf="(itemRoles.asWeapon?.hands || uncertainItemLiteralValue('hands')) as hands">
            <strong>Hands</strong>
            {{hands}}
        </span>
        <span *ngIf="itemRoles.asWeapon?.ranged">
            <strong>Range</strong>
            {{itemRoles.asWeapon?.ranged}} ft.
        </span>
        <span *ngIf="itemRoles.asWeapon?.reload">
            <strong>Reload</strong>
            {{itemRoles.asWeapon?.reload}}
        </span>
    </div>
    <div class="newrow left-aligned" *ngIf="item.access">
        <span>
            <strong>Access</strong>
            <i> {{item.access}}</i>
        </span>
    </div>
    <!--Activation text for activated standalone items, such as wands (identified by not having a trigger or requirements)-->
    <div class="newrow gap-text left-aligned" *ngIf="shouldShowActivations()">
        <strong>Activate</strong>
        <app-actionIcons *ngIf="uncertainItemLiteralValue('actions') as actions" [actionString]="actions.toString()">
        </app-actionIcons>
        <span *ngIf="uncertainItemLiteralValue('activationType') as activationType">
            {{activationType}}
        </span>
        <ng-container *ngIf="uncertainItemLiteralValue('frequency') as frequency">
            <strong>Frequency</strong>
            <span>
                {{frequency}}
            </span>
        </ng-container>
        <ng-container *ngIf="uncertainItemLiteralValue('effect') as effect">
            <strong>Effect</strong>
            <app-description [text]="effect.toString()" [oneLiner]="true"></app-description>
        </ng-container>
    </div>
    <div class="newrow left-aligned">
        <span *ngIf="uncertainItemLiteralValue('ammunition') as ammunition">
            <strong>Ammunition</strong>
            {{ammunition}}
        </span>
    </div>
    <!--Activation text for talismans (identified by having a trigger or requirements, but no effect)-->
    <div class="newrow left-aligned" *ngIf="shouldShowTalismans()">
        <strong>Activate</strong>
        <app-actionIcons *ngIf="uncertainItemLiteralValue('actions') as actions" [actionString]="actions.toString()">
        </app-actionIcons>
        <span *ngIf="uncertainItemLiteralValue('activationType') as activationType">
            {{activationType}}
        </span>
        <span *ngIf="uncertainItemLiteralValue('trigger') as trigger">
            <strong>Trigger</strong>
            {{trigger}}
        </span>
        <span *ngIf="uncertainItemLiteralValue('requirements') as requirements">
            <strong>Requirements</strong>
            {{requirements}}
        </span>
    </div>
    <div class="newrow left-aligned">
        <hr class="newrow">
        <ng-container *ngFor="let desc of item.desc.split('\n\n'); trackBy:trackers.trackByIndex;">
            <app-description class="newrow" [text]="desc"></app-description>
        </ng-container>
        <ng-container *ngIf="languageGainsFromItem() as languageGains">
            <div class="newrow list-item" *ngIf="languageGains.length">
                <ng-container
                    *ngFor="let gainLanguage of languageGains; let languageIndex = index; trackBy:trackers.trackByIndex;">
                    <div class="newrow">
                        <strong>{{gainLanguage.title}}</strong>
                        <input id="{{item.id}}language{{languageIndex}}name" type="text" [(ngModel)]="gainLanguage.name"
                            (blur)="onUpdateLanguage()" *ngIf="!gainLanguage.locked">
                        <ng-container *ngIf="gainLanguage.locked">
                            {{gainLanguage.name}}
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </ng-container>
        <div class="newrow list-item" *ngIf="doesItemHaveShownData()">
            <ng-container *ngFor="let data of item.data; let dataIndex = index; trackBy:trackers.trackByIndex;">
                <div class="newrow" *ngIf="data.show">
                    <strong>{{data.name}}</strong>
                    <input id="{{item.id}}data{{dataIndex}}value" type="text" [(ngModel)]="data.value"
                        *ngIf="data.type === 'string'">
                    <input id="{{item.id}}data{{dataIndex}}value" type="checkbox" [(ngModel)]="data.value"
                        *ngIf="data.type === 'boolean'">
                </div>
            </ng-container>
        </div>
        <!--Alchemical elixir data-->
        <ng-container *ngIf="asAlchemicalElixir() as asAlchemicalElixir">
            <p *ngIf="asAlchemicalElixir.benefit" class="gap-text">
                <strong>Benefit</strong>
                <app-description [text]="asAlchemicalElixir.benefit" [oneLiner]="true"></app-description>
            </p>
            <p *ngIf="asAlchemicalElixir.drawback" class="gap-text">
                <strong>Drawback</strong>
                <app-description [text]="asAlchemicalElixir.drawback" [oneLiner]="true"></app-description>
            </p>
        </ng-container>
        <!--Alchemical poison data-->
        <ng-container *ngIf="asAlchemicalPoison() as asAlchemicalPoison">
            <p
                *ngIf="asAlchemicalPoison.savingThrow || asAlchemicalPoison.maxDuration || asAlchemicalPoison.stages?.length">
                <span *ngIf="asAlchemicalPoison.savingThrow">
                    <strong>Saving Throw</strong>
                    {{asAlchemicalPoison.savingThrow}}
                </span>
                <span *ngIf="asAlchemicalPoison.stages[0]">
                    <strong>Onset</strong>
                    {{asAlchemicalPoison.stages[0]}}
                </span>
                <span *ngIf="asAlchemicalPoison.maxDuration">
                    <strong>Maximum Duration</strong>
                    {{asAlchemicalPoison.maxDuration}}
                </span>
                <ng-container
                    *ngFor="let stage of asAlchemicalPoison.stages; let stageIndex = index; trackBy:trackers.trackByIndex;">
                    <span *ngIf="stageIndex > 0">
                        <strong>Stage {{stageIndex}}</strong>
                        {{asAlchemicalPoison.stages[stageIndex]}}
                    </span>
                </ng-container>
            </p>
        </ng-container>
        <p *ngIf="item.subTypeDesc">
            <strong *ngIf="item.subType">{{item.subType}}</strong>
            {{item.subTypeDesc}}
        </p>
        <p *ngIf="item.craftRequirement">
            <strong>Craft Requirement</strong> {{item.craftRequirement}}
        </p>
        <ng-container *ngIf="item.hasSuccessResults()">
            <ul>
                <li *ngIf="doesItemHaveValue('critsuccess')">
                    <span class="gap-text">
                        <strong>Critical Success</strong>
                        <app-description [text]="item.critsuccess" [oneLiner]="true"></app-description>
                    </span>
                </li>
                <li *ngIf="doesItemHaveValue('success')">
                    <span class="gap-text">
                        <strong>Success</strong>
                        <app-description [text]="item.success" [oneLiner]="true"></app-description>
                    </span>
                </li>
                <li *ngIf="doesItemHaveValue('failure')">
                    <span class="gap-text">
                        <strong>Failure</strong>
                        <app-description [text]="item.failure" [oneLiner]="true"></app-description>
                    </span>
                </li>
                <li *ngIf="doesItemHaveValue('critfailure')">
                    <span class="gap-text">
                        <strong>Critical Failure</strong>
                        <app-description [text]="item.critfailure" [oneLiner]="true"></app-description>
                    </span>
                </li>
            </ul>
        </ng-container>
        <div class="list-item newrow problem" *ngIf="item.inputRequired">
            <strong>Player input required:</strong>
            <div class="newrow left-aligned"
                *ngFor="let inputRequired of item.inputRequired.split('\n\n'); trackBy:trackers.trackByIndex;">
                <app-description class="newrow" [text]="inputRequired">
                </app-description>
            </div>
        </div>
    </div>
</ng-container>
