<!-- eslint-disable @angular-eslint/template/cyclomatic-complexity -->
<div class="window-button-container">
    <button class="tilemodebutton" [ngClass]="{'fancy-button':isTileMode}" (click)="toggleTileMode()"
        [ngbTooltip]="isTileMode ? 'Click to enable list layout' : 'Click to enable tiled layout'">
        <!--Content is css only-->
        <div>
            <div> </div>
            <div> </div>
            <div> </div>
            <div> </div>
        </div>
    </button>
</div>
<div id="crafting" class="itembox vlist">
    <button class="itembox-close-button list-item center-aligned" (click)="toggleCraftingMenu()">
        <header class="sectionHeader">Back to Character Sheet</header>
    </button>
    <div class="loading" *ngIf="stillLoading">Loading</div>
    <ng-container *ngIf="!stillLoading && craftingMenuState==='in'">
        <div class="charactersheet-column-container">
            <div class="charactersheet-column">
                <div class="list-item">
                    <strong>Find (in Name, Description or Traits)</strong>
                    <span class="hlist">
                        <input type=text [(ngModel)]="wordFilter" (ngModelChange)=closeFilterIfTooShort() />
                        <button (click)="setFilterForAll()" [disabled]="wordFilter.length < 5">Show All</button>
                        <button (click)="wordFilter='';closeFilterIfTooShort()">Clear</button>
                    </span>
                </div>
                <div class="list-item">
                    <strong>Sort by</strong>
                    <div class="newrow">
                        <button class="center-aligned" [ngClass]="{'fancy-button':shownSorting()==='name'}"
                            (click)="toggleShownSorting('name')">Name</button>
                        <button class="center-aligned" [ngClass]="{'fancy-button':shownSorting()==='sortLevel'}"
                            (click)="toggleShownSorting('sortLevel')">Level</button>
                    </div>
                </div>
                <div class="fullsize-scroll-box vlist">
                    <header class="subsectionHeader">Change Currency</header>
                    <app-cash></app-cash>
                    <ng-container *ngIf="craftingItems() as items">
                        <ng-container *ngIf="snareSpecialistParameters(items) as snareSpecialistParameters">
                            <header class="sectionHeader">Snare Specialist</header>
                            <div class="list-item">
                                You can prepare {{snareSpecialistParameters.available -
                                snareSpecialistParameters.prepared}} of
                                {{snareSpecialistParameters.available}} snares for quick deployment.
                            </div>

                            <ng-container *ngIf="'snarespecialist' as listID">
                                <button class="list-item"
                                    [ngClass]="{'invisible':!snareSpecialistParameters.snares.length}"
                                    (click)="toggleShownList(listID)">
                                    <header class="sectionHeader">Snares</header>
                                </button>
                                <div [ngClass]="{'icon-list':isTileMode, 'list-item':!isTileMode}"
                                    [ngClass]="{'invisible':!snareSpecialistParameters.snares.length}"
                                    *ngIf="[listID, 'all'].includes(shownList())">
                                    <ng-container
                                        *ngFor="let snareParameters of snareParameters(snareSpecialistParameters.snares); trackBy:trackers.trackByIndex;">
                                        <ng-template #SnareTemplate>
                                            <header class="spellHeader newrow" *ngIf="isTileMode">
                                                {{snareParameters.preparedAmount}}
                                                {{snareParameters.snare.effectiveName()}}
                                            </header>
                                            <span>
                                                <button class="center-aligned"
                                                    (click)="onPrepareForQuickCrafting(snareParameters.snare, -1)"
                                                    [disabled]="!snareParameters.preparedAmount">
                                                    -
                                                </button>
                                                <button class="center-aligned"
                                                    (click)="onPrepareForQuickCrafting(snareParameters.snare, 1)"
                                                    [disabled]="snareSpecialistParameters.available <= snareSpecialistParameters.prepared">
                                                    +
                                                </button>
                                                Prepare for quick deployment
                                            </span>
                                            <app-item class="lower" [item]=snareParameters.snare [itemStore]=true>
                                            </app-item>
                                        </ng-template>
                                        <ng-container *ngIf="!isTileMode">
                                            <div class="list-item"
                                                *ngIf="listID+snareParameters.snare.id as inventoryID">
                                                <button class="newrow sublist-toggle"
                                                    [ngClass]="{'fancy-button':snareParameters.preparedAmount}"
                                                    (click)="toggleShownItem(inventoryID)">
                                                    <span>
                                                        {{snareParameters.preparedAmount}}
                                                        {{snareParameters.snare.effectiveName()}}
                                                    </span>
                                                    <span>
                                                        {{(snareParameters.snare.level)
                                                        ? "Level "+snareParameters.snare.level
                                                        : "&nbsp;"}}
                                                    </span>
                                                </button>
                                                <div class="list-item sublist lower"
                                                    [ngClass]="{'fancy-list':snareParameters.preparedAmount}"
                                                    *ngIf="shownItem()===inventoryID">
                                                    <ng-container *ngTemplateOutlet="SnareTemplate">
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="isTileMode">
                                            <button [ngbPopover]="SnareTemplate" triggers="click"
                                                [ngClass]="{'fancy-button':snareParameters.preparedAmount}"
                                                (click)="toggleShownItem()">
                                                <app-gridIcon
                                                    [ngClass]="{'fancy-button':snareParameters.preparedAmount}"
                                                    [ngbTooltip]="(snareParameters.preparedAmount ? snareParameters.preparedAmount + ' ' : '') + snareParameters.snare.effectiveName({itemStore:true}) + (snareParameters.snare.level ? ' (Level ' + snareParameters.snare.level + ')' : '')"
                                                    [subTitle]="snareParameters.preparedAmount.toString()"
                                                    [title]="snareParameters.snare.gridIconTitle()"
                                                    [detail]="(snareParameters.snare.traits.includes('Rare') ? 'R' : (snareParameters.snare.traits.includes('Uncommon') ? 'U' : (snareParameters.snare.traits.includes('Unique') ? 'Q' : ''))) + (snareParameters.snare.level || '')"
                                                    [item]="snareParameters.snare">
                                                </app-gridIcon>
                                            </button>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </ng-container>
                        <header class="sectionHeader">Craft Items</header>
                        <app-tags [creature]="creature" [objectName]="'Crafting'" [showTraits]=true [showFeats]=true
                            [showItems]=true [showActivities]=true [showConditions]=true [showEffects]=true></app-tags>
                        <div class="list-item">
                            <strong
                                [ngbPopover]="'The material cost of Crafting an item can vary depending on your success using the Craft activity. If you Craft an item in any way that requires you to spend materials, manually spend the appropriate amount of currency and any required items.'">
                                About crafting costs
                                <i class="bi-question-circle"></i>
                            </strong>
                        </div>
                        <div class="list-item">
                            <strong
                                [ngbPopover]="'When available, a shoddy item usually costs half the Price of a standard item, though you can never sell one in any case. Attacks and checks involving a shoddy item take a -2 item penalty. This penalty also applies to any DCs that a shoddy item applies to (such as AC, for shoddy armor). A shoddy suit of armor also worsens the armor\'s check penalty by 2. A shoddy item\'s Hit Points and Broken Threshold are each half that of a normal item of its type.'">
                                About shoddy items
                                <i class="bi-question-circle"></i>
                            </strong>
                        </div>
                        <ng-container
                            *ngFor="let itemSet of items.names; let index = index; trackBy:trackers.trackByIndex;">
                            <ng-container *ngIf="''+(index*1000+1000) as listID">
                                <ng-container *ngIf="visibleItems(items, itemSet.key) as visibleItems">
                                    <button class="list-item"
                                        [ngStyle]="{'display':!visibleItems.length ? 'none' : 'flex' }"
                                        (click)="toggleShownList(listID)">
                                        <header class="sectionHeader">{{itemSet.name}}</header>
                                    </button>
                                    <div [ngClass]="{'icon-list':isTileMode, 'list-item':!isTileMode}"
                                        [ngStyle]="{'display':!visibleItems.length ? 'none' : 'flex' }"
                                        *ngIf="[listID, 'all'].includes(shownList())">
                                        <div class="newrow list-item"
                                            *ngIf="visibleItems.length >= 80 && shownList()!=='all'"
                                            (click)="incRange(-1)">
                                            <button class="center-aligned" [disabled]="range <= 0">
                                                Previous 40
                                            </button>
                                            <header class="newrow subsectionHeader center-aligned">
                                                {{shownItemRangeDesc(visibleItems, range)}}
                                            </header>
                                        </div>
                                        <ng-container
                                            *ngFor="let itemParameters of visibleItemParameters(visibleItems); let itemIndex = index; trackBy:trackers.trackByIndex;">
                                            <ng-container *ngIf="isItemShown(visibleItems, itemIndex, range)">
                                                <ng-template #ItemTitleTemplate>
                                                    <span>
                                                        <i class="value bi-x penalty"
                                                            *ngIf="!isTileMode && itemParameters.canUse === false"
                                                            [ngbTooltip]="'You are not trained with this item.'">
                                                        </i>
                                                        <i class="value bi-check2 bonus"
                                                            *ngIf="!isTileMode && itemParameters.canUse"
                                                            [ngbTooltip]="'You are trained with this item.'">
                                                        </i>
                                                        {{itemParameters.item.effectiveName()}}
                                                    </span>
                                                    <span style="flex-basis:auto;flex-grow:0;">
                                                        <span *ngIf="itemParameters.item.level">
                                                            {{"Level "+itemParameters.item.level}}
                                                        </span>
                                                        <span *ngIf="itemParameters.asArmor">
                                                            {{itemParameters.asArmor.title({itemStore: true})}}
                                                        </span>
                                                        <span *ngIf="itemParameters.asWeapon">
                                                            {{itemParameters.asWeapon.title()}}
                                                        </span>
                                                    </span>
                                                </ng-template>
                                                <ng-template #ItemTooltipTemplate>
                                                    <span>
                                                        {{itemParameters.item.effectiveName({itemStore:true})}}
                                                    </span>
                                                    <span *ngIf="itemParameters.item.level">
                                                        |
                                                    </span>
                                                    <span *ngIf="itemParameters.item.level">
                                                        {{"Level "+itemParameters.item.level}}
                                                    </span>
                                                    <span *ngIf="itemParameters.asArmor || itemParameters.asWeapon">
                                                        |
                                                    </span>
                                                    <span *ngIf="itemParameters.asArmor">
                                                        {{itemParameters.asArmor.title({itemStore: true})}}
                                                    </span>
                                                    <span *ngIf="itemParameters.asWeapon">
                                                        {{itemParameters.asWeapon.title()}}
                                                    </span>
                                                </ng-template>
                                                <ng-template #ItemTemplate>
                                                    <header class="spellHeader newrow" *ngIf="isTileMode">
                                                        <ng-container *ngTemplateOutlet="ItemTitleTemplate">
                                                        </ng-container>
                                                    </header>
                                                    <ng-container>
                                                        <div class="newrow" [ngbTooltip]="cannotCraftReason.join('\n')"
                                                            *ngIf="cannotCraftReason(itemParameters.item) as cannotCraftReason">
                                                            <button class=" newrow center-aligned"
                                                                (click)="craftItem(itemParameters.item)"
                                                                [disabled]="cannotCraftReason.length">
                                                                Craft
                                                                {{(itemParameters?.stack || 0) > 1 ?
                                                                itemParameters.stack: ""}}
                                                                using Craft activity
                                                            </button>
                                                        </div>
                                                    </ng-container>
                                                    <app-itemMaterial class="newrow"
                                                        *ngIf="itemParameters.asMaterialChangeable"
                                                        [item]="itemParameters.asMaterialChangeable"
                                                        [itemRoles]="itemParameters" [craftingStation]="true">
                                                    </app-itemMaterial>
                                                    <div class="newrow list-item left-aligned"
                                                        *ngIf="itemParameters.asEquipment">
                                                        <input id="{{itemParameters.item.id}}shoddy" type="checkbox"
                                                            [(ngModel)]="itemParameters.asEquipment.shoddy">
                                                        <label for="{{itemParameters.item.id}}shoddy">Shoddy</label>
                                                    </div>
                                                    <app-item class="lower" [item]="itemParameters.item"
                                                        [itemStore]=true></app-item>
                                                </ng-template>
                                                <ng-container *ngIf="!isTileMode">
                                                    <div class="list-item"
                                                        *ngIf="(listID+itemParameters.item.id) as inventoryID">
                                                        <ng-container *ngIf="!isTileMode"></ng-container>
                                                        <button class="newrow sublist-toggle"
                                                            (click)="toggleShownItem(inventoryID)">
                                                            <ng-container *ngTemplateOutlet="ItemTitleTemplate">
                                                            </ng-container>
                                                        </button>
                                                        <div class="list-item sublist lower"
                                                            *ngIf="shownItem()===inventoryID">
                                                            <ng-container *ngTemplateOutlet="ItemTemplate">
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="isTileMode">
                                                    <button [ngbPopover]="ItemTemplate" triggers="click"
                                                        [ngClass]="{'penalty': itemParameters.canUse === false, 'bonus': itemParameters.canUse}"
                                                        (click)="toggleShownItem()">
                                                        <app-gridIcon [updateId]="itemParameters.item.id"
                                                            [ngClass]="{'penalty': itemParameters.canUse === false, 'bonus': itemParameters.canUse}"
                                                            [ngbTooltip]="ItemTooltipTemplate"
                                                            [title]="itemParameters.item.gridIconTitle()"
                                                            [detail]="(itemParameters.item.traits.includes('Rare') ? 'R' : (itemParameters.item.traits.includes('Uncommon') ? 'U' : (itemParameters.item.traits.includes('Unique') ? 'Q' : ''))) + (itemParameters.item.level || '')"
                                                            [item]="itemParameters.item" [itemStore]="true">
                                                        </app-gridIcon>
                                                    </button>
                                                </ng-container>
                                            </ng-container>
                                        </ng-container>
                                        <div class="newrow list-item"
                                            *ngIf="visibleItems.length >= 80 && shownList()!=='all'">
                                            <button class="center-aligned"
                                                [disabled]="(range + 1) * 40 >= visibleItems.length"
                                                (click)="incRange(1)">
                                                Next 40
                                            </button>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
            <div class="charactersheet-column mobile-hide">
                <app-inventory [itemStore]="true" id="ItemStore-inventory"
                    [ngClass]="{'minimized':isInventoryMinimized}"></app-inventory>
            </div>
        </div>
    </ng-container>
</div>
