<!-- eslint-disable @angular-eslint/template/cyclomatic-complexity -->
<div class="list-item newrow">
    <p>
        Welcome to PECS, a largely automated character tool for Pathfinder RPG 2nd Edition. This tool is meant to allow
        you to build your character according to the rules and options in PFRPG 2e and then use that character in play.
    </p>
</div>
<ng-container *ngIf="updateAvailable() as updateAvailable">
    <div class="list-item newrow center-aligned" *ngIf="updateAvailable.available">
        <a *ngIf="updateAvailable.desc !== 'n/a'" href='https://github.com/bukiro/PECS/releases/latest' target="_blank"
            rel="noreferrer noopener">{{updateAvailable.desc}}</a>
        <span *ngIf="updateAvailable.desc === 'n/a'">{{updateAvailable.desc}}</span>
    </div>
</ng-container>
<div class="newrow list-item left-aligned lower" *ngIf="version">
    <header class="spellHeader">
        New in version
        <select [(ngModel)]="version">
            <option *ngFor="let versionChange of changelog; trackBy:trackers.trackByIndex;"
                [ngValue]="versionChange.version">
                {{versionChange.version}}
            </option>
        </select>
    </header>
    <div class="newrow list-item left-aligned"
        *ngFor="let change of versionShown()?.changes; trackBy:trackers.trackByIndex;">
        <header class="subsectionHeader" *ngIf="change.header">
            {{change.header}}
        </header>
        <app-description *ngFor="let split of change.desc.split('\n\n'); trackBy:trackers.trackByIndex" [text]="split">
        </app-description>
        <div class="newrow list-item center-aligned" *ngFor="let image of change.images; trackBy:trackers.trackByIndex">
            <img style="max-width:100%; flex-grow: 0;" title="{{change.header}}" src="assets/img/about/{{image.file}}">
            <span class="newrow center-aligned" *ngIf="image.title">{{image.title}}</span>
        </div>
    </div>
</div>
<ng-container *ngFor="let paragraph of welcome; trackBy: trackers.trackByIndex;">
    <div class="list-item newrow">
        <div class="newrow left-aligned">
            <header class="spellHeader" *ngIf="paragraph.header">
                {{paragraph.header}}
            </header>
            <app-description *ngFor="let split of paragraph.desc.split('\n\n'); trackBy:trackers.trackByIndex"
                [text]="split">
            </app-description>
        </div>
    </div>
</ng-container>
<div class="list-item newrow">
    <header class="spellHeader">
        Hints and Tips
    </header>
    <ng-container *ngFor="let hint of hints; trackBy:trackers.trackByIndex;">
        <div class="newrow list-item left-aligned">
            <header class="subsectionHeader" *ngIf="hint.header">
                {{hint.header}}
            </header>
            <app-description *ngFor="let split of hint.desc.split('\n\n'); trackBy:trackers.trackByIndex"
                [text]="split">
            </app-description>
            <div class="newrow list-item center-aligned"
                *ngFor="let image of hint.images; trackBy:trackers.trackByIndex">
                <img style="max-width:100%; flex-grow: 0;" title="{{hint.header}}"
                    src="assets/img/about/{{image.file}}">
                <span class="newrow center-aligned" *ngIf="image.title">{{image.title}}</span>
            </div>
        </div>
    </ng-container>
</div>
