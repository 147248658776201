/* eslint-disable @typescript-eslint/no-magic-numbers */
export const Defaults = {
    maxCharacterLevel: 20,
    abilityBaseValue: 10,
    expPerLevel: 1000,
    familiarAbilities: 2,
    startingGold: 15,
    wornToolsInventoryBulkLimit: 2,
    defaultFamiliarSpeed: 25,
    playerMessageTTL: 600,
    foundryMessageTTL: 500,
    colorAccent: '#465A8C',
    colorAccentRGB: '25, 118, 210',
    tooltipDelay: 100,
    maxFocusPoints: 3,
    maxHeroPoints: 3,
    mobileBreakpointPx: 992,
    waitForServiceDelay: 100,
    maxInvestedItems: 10,
    maxInvestedAeonStones: 1,
};
