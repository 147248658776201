<!--eslint-disable @angular-eslint/template/cyclomatic-complexity-->
<ng-container *ngFor="let hint of hints(); trackBy:trackers.trackByIndex;">
    <ng-container *ngIf="hintSource(hint) as source">
        <ng-template #SimpleHintContent>
            <ng-container
                *ngFor="let text of hintDescription(hint).split('\n\n'); let textIndex = index; trackBy:trackers.trackByIndex;">
                <app-description class="newrow" [text]="text"></app-description>
            </ng-container>
        </ng-template>
        <ng-template #HintContent>
            <ng-container
                *ngFor="let text of hintDescription(hint).split('\n\n'); let textIndex = index; trackBy:trackers.trackByIndex;">
                <app-description class="newrow" [text]="text"></app-description>
            </ng-container>
            <label *ngIf="objectHasType(source)">
                <div class="button newrow center-aligned"
                    [ngClass]="{'fancy-button': character.settings.hintsShowMoreInformation}">
                    {{character.settings.hintsShowMoreInformation ? 'Show Less' : 'Show More'}}
                    <input type="checkbox" [(ngModel)]="character.settings.hintsShowMoreInformation" hidden>
                </div>
            </label>
            <div class="list-item"
                *ngIf="character.settings.hintsShowMoreInformation && objectAsNamedObject(source) as namedObject">
                <header class="spellHeader" *ngIf="namedObject.name">{{namedObject.name}}</header>
                <div class="newrow left-aligned" *ngIf="sourceBook">
                    <strong>Source</strong>
                    <i>{{sourceBook}}</i>
                </div>
                <app-feat *ngIf="objectAsFeat(source) as feat" [feat]="feat">
                </app-feat>
                <app-condition *ngIf="objectAsConditionSet(source) as conditionSet" [condition]="conditionSet.condition"
                    [conditionGain]="conditionSet.gain">
                </app-condition>
                <ng-container *ngIf="objectAsActivity(source) as activity">
                    <div class="newrow left-aligned">
                        <app-trait *ngFor="let trait of activity.traits; trackBy:trackers.trackByIndex;" [name]="trait"
                            [trait]="traitFromName(trait)">
                        </app-trait>
                        <app-trait *ngFor="let trait of activity.activationTraits(); trackBy:trackers.trackByIndex;"
                            [name]="trait" [trait]="traitFromName(trait)"
                            [extraDescription]="'(This trait was derived from the action components.)'">
                        </app-trait>
                    </div>
                    <app-activityContent class="fullwidth vlist lower" [creature]="creature" [allowActivate]="false"
                        [activity]="activity" [cooldown]="activityCooldown(activity)">
                    </app-activityContent>
                </ng-container>
                <app-hintItem *ngIf="objectAsItem(source) as item" [item]="item" [creature]="creature">
                </app-hintItem>
                <div class="newrow left-aligned" *ngIf="objectAsDescOnly(source) as descOnly">
                    <app-description class="newrow" [text]="descOnly.desc"></app-description>
                </div>
            </div>
        </ng-template>
        <cite class="{{color}}">
            <span #fullDetailPopover="ngbPopover" [ngbPopover]="HintContent" triggers="click">
                <span [ngbPopover]="(!fullDetailPopover.isOpen() && SimpleHintContent) || undefined" triggers="hover">
                    {{hint.replaceTitle || description}}{{hintChoice(hint)}}
                </span>
            </span>
            <ng-container *ngIf="hint.effects && hint.effects.length">
                <input type="checkbox" [(ngModel)]="hint.active" (ngModelChange)="onActivateEffect()"
                    [ngbTooltip]="'Activate any effects of this hint.'" triggers="hover">
                <input type="checkbox" [(ngModel)]="hint.active2" (ngModelChange)="onActivateEffect()"
                    *ngIf="hint.extraActivations > 0"
                    [ngbTooltip]="'Activate the second level of this hint\'s effects.'" triggers="hover">
                <input type="checkbox" [(ngModel)]="hint.active3" (ngModelChange)="onActivateEffect()"
                    *ngIf="hint.extraActivations > 1" [ngbTooltip]="'Activate the third level of this hint\'s effects.'"
                    triggers="hover">
                <input type="checkbox" [(ngModel)]="hint.active4" (ngModelChange)="onActivateEffect()"
                    *ngIf="hint.extraActivations > 2"
                    [ngbTooltip]="'Activate the fourth level of this hint\'s effects.'" triggers="hover">
                <input type="checkbox" [(ngModel)]="hint.active5" (ngModelChange)="onActivateEffect()"
                    *ngIf="hint.extraActivations > 3" [ngbTooltip]="'Activate the fifth level of this hint\'s effects.'"
                    triggers="hover">
            </ng-container>
        </cite>
    </ng-container>
</ng-container>
