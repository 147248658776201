<!-- eslint-disable @angular-eslint/template/cyclomatic-complexity -->
<button class="minimizebutton lower"
    [ngbTooltip]="isMinimized ? 'Click to show all information.' : 'Click to show compact information.'"
    [ngClass]="{'fancy-button':isMinimized}" (click)="minimize()" *ngIf="creature==='Character'">
    <i class='bi-arrows-collapse' *ngIf="isMinimized"></i>
    <i class='bi-arrows-expand' *ngIf="!isMinimized"></i>
</button>
<div id="{{creature}}-defense-height" class="attributeBox">
    <ng-container *ngIf="componentParameters() as componentParameters">
        <ng-template #EffectsPopoverContent>
            <div class="fullsize-only newrow">
                <app-objectEffects [creature]="creature" [objectName]="'AC'"></app-objectEffects>
            </div>
        </ng-template>
        <header class="sectionHeader box-header">
            <span class="fullsize-only" [ngbPopover]="EffectsPopoverContent" #EffectsPopover="ngbPopover"
                triggers="click">
                <i [ngbTooltip]="!EffectsPopover.isOpen() ? 'Edit effects' : ''" class='bi-lightning-charge'></i>
            </span>
            Defense: Armor Class
            <span [ngbPopover]="componentParameters.calculatedAC.value.explain"
                [ngClass]="{'penalty':componentParameters.calculatedAC.penalties, 'bonus':componentParameters.calculatedAC.bonuses, 'absolute':componentParameters.calculatedAC.absolutes.length}">
                {{componentParameters.calculatedAC.value.result}}
            </span>
        </header>
        <div class="loading" *ngIf="stillLoading">Loading</div>
        <ng-container *ngIf="!stillLoading">
            <app-tags [creature]="creature" [objectName]="'AC'" [showTraits]=true [showFeats]=true [showItems]=true
                [showActivities]=true [showConditions]=true [showEffects]=false
                [specialEffects]="componentParameters.calculatedAC.relatives.concat(componentParameters.calculatedAC.absolutes)">
            </app-tags>
            <app-tags [creature]="creature" [objectName]="'Defense'" [showTraits]=true [showFeats]=true [showItems]=true
                [showActivities]=true [showConditions]=true [showEffects]=true></app-tags>
            <div class="list-item newrow">
                <strong>Quick Status</strong>
                <span class="hlist left-aligned">

                    <button class="center-aligned bigger" [ngClass]="{'fancy-button': componentParameters.flatFooted}"
                        [ngbTooltip]="componentParameters.flatFooted ? 'Disable Flat-Footed' : 'Flat-Footed'"
                        (click)="onSetFlatFooted(!componentParameters.flatFooted)">
                        <i class="ra ra-footprint"></i>
                    </button>

                    <button class="center-aligned bigger" [ngClass]="{'fancy-button': componentParameters.hidden}"
                        [ngbTooltip]="componentParameters.hidden ? 'Disable Hidden' : 'Hidden'"
                        (click)="onSetHidden(!componentParameters.hidden)">
                        <i class="ra ra-player-dodge"></i>
                    </button>

                </span>

                <strong>Cover</strong>
                <span class="hlist right-aligned">
                    <button class="center-aligned bigger" [ngClass]="{'fancy-button': componentParameters.cover === 1}"
                        [ngbTooltip]="componentParameters.cover === 1 ? 'End Lesser Cover' : 'Lesser Cover'"
                        (click)="onSetCover(componentParameters.cover === 1 ? 0 : 1)">
                        <i class="ra ra-player"></i>
                    </button>
                    <button class="center-aligned bigger" [ngClass]="{'fancy-button': componentParameters.cover === 2}"
                        [ngbTooltip]="componentParameters.cover === 2 ? 'End Standard Cover' : 'Standard Cover'"
                        (click)="onSetCover(componentParameters.cover === 2 ? 0 : 2)">
                        <i class="ra ra-metal-gate"></i>
                    </button>
                    <button class="center-aligned bigger" [ngClass]="{'fancy-button': componentParameters.cover === 4}"
                        [ngbTooltip]="componentParameters.cover === 4 ? 'End Greater Cover' : 'Greater Cover'"
                        (click)="onSetCover(componentParameters.cover === 4 ? 0 : 4)">
                        <i class="ra ra-tower"></i>
                    </button>
                </span>
            </div>
            <ng-container *ngIf="creature !== 'Familiar'">
                <header class="subsectionHeader fullsize-only">Armor</header>
                <div class="fullsize-only list-item"
                    *ngFor="let armor of equippedArmor(); let armorIndex = index; trackBy:trackers.trackByIndex;">
                    <div>
                        {{armor.effectiveName()}}
                    </div>
                    <div class="fullsize-only newrow left-aligned" *ngIf="armor.traits.length">
                        <app-trait *ngFor="let trait of armor.traits; trackBy:trackers.trackByIndex;" [name]="trait"
                            [trait]="traitFromName(trait)">
                        </app-trait>
                    </div>
                    <app-tags [creature]="creature" [objectName]="'Armor'" [showTraits]=true [showFeats]=true
                        [showItems]=true [showActivities]=true [showConditions]=true [showEffects]=true
                        *ngIf="armorIndex === 0"></app-tags>
                    <app-tags [creature]="creature" [objectName]="armor.name" [showItems]=true></app-tags>
                    <ng-container *ngIf="hintShowingRunes(armor) as hintShowingRunes">
                        <div class="fullsize-only newrow left-aligned" *ngIf="hintShowingRunes.length"
                            style="margin-top:initial">
                            <ng-container *ngFor="let rune of hintShowingRunes; trackBy:trackers.trackByIndex;">
                                <ng-container *ngFor="let hint of rune.hints; trackBy:trackers.trackByIndex;">
                                    <ng-template #RuneDescTemplate>
                                        <div class="newrow left-aligned" *ngIf="rune.sourceBook">
                                            <strong>Source</strong>
                                            <i>{{rune.sourceBook}}</i>
                                        </div>
                                        <div class="newrow left-aligned">
                                            {{heightenedHintText(hint)}}
                                        </div>
                                    </ng-template>
                                    <cite class="item" [ngbPopover]="RuneDescTemplate">
                                        {{rune.name}} Rune
                                    </cite>
                                </ng-container>
                            </ng-container>
                        </div>
                    </ng-container>
                    <div class="fullsize-only lower newrow left-aligned"
                        *ngFor="let talisman of armor.talismans; let index = index; trackBy:trackers.trackByIndex;">
                        <ng-template #TalismanTemplate>
                            <div class="list-item left-aligned lower"
                                *ngFor="let cord of armor.talismanCords; trackBy:trackers.trackByIndex;">
                                <header class="spellHeader newrow">
                                    <span>
                                        {{cord.name}}
                                    </span>
                                    <span>
                                        Level {{cord.level}}
                                    </span>
                                </header>
                                <p>When you activate a talisman threaded through a cord with the same magic school trait
                                    that's also the cord's level or lower, attempt a <app-quickdice
                                        [diceString]="'1d20'"> </app-quickdice> DC 16 flat check. On a success, that
                                    talisman is not consumed and can be used again.
                                </p>
                                <ng-container
                                    *ngFor="let talismanCordIndex of [0, 1, 2]; trackBy:trackers.trackByIndex;">
                                    <div class="newrow"
                                        *ngIf="cord.isTalismanCord > talismanCordIndex && cord.data[talismanCordIndex]">
                                        <strong>{{cord.data[talismanCordIndex].name}}</strong>
                                        {{cord.data[talismanCordIndex].value}}
                                    </div>
                                </ng-container>
                            </div>
                            <header class="spellHeader newrow">
                                <span>
                                    {{talisman.name}}
                                </span>
                                <span>
                                    Level {{talisman.level}}
                                </span>
                            </header>
                            <button class="newrow left-aligned" (click)="onTalismanUse(armor, talisman, index)">
                                <span>
                                    Activate
                                    <app-actionIcons *ngIf="talisman.actions" [actionString]="talisman.actions">
                                    </app-actionIcons>
                                    {{(talisman.activationType) ? talisman.activationType : ""}}
                                </span>
                            </button>
                            <button class="newrow left-aligned" (click)="onTalismanUse(armor, talisman, index, true)"
                                *ngIf="hasMatchingTalismanCord(armor, talisman)">
                                <span>
                                    Activate and preserve with talisman cord
                                    <app-actionIcons *ngIf="talisman.actions" [actionString]="talisman.actions">
                                    </app-actionIcons>
                                    {{(talisman.activationType) ? talisman.activationType : ""}}
                                </span>
                            </button>
                            <app-item [creature]="creature" [item]=talisman [allowActivate]=true [isSubItem]=true>
                            </app-item>
                        </ng-template>
                        <button [ngbPopover]="TalismanTemplate" triggers="click">
                            <span>
                                {{talisman.name}}
                                {{hasMatchingTalismanCord(armor, talisman) ?
                                "(matching talisman cord attached)" : ""}}
                            </span>
                        </button>
                    </div>
                    <div class="fullsize-only lower newrow left-aligned"
                        *ngFor="let spec of armorSpecialization(armor); trackBy:trackers.trackByIndex;">
                        <strong>Armor specialization effect&nbsp;</strong>{{spec.desc}}
                    </div>
                </div>

                <ng-container *ngFor="let shield of equippedShield(); trackBy:trackers.trackByIndex;">
                    <header class="subsectionHeader fullsize-only">Shield</header>
                    <div class="vlist list-item">
                        <div class="newrow left-aligned">{{shield.effectiveName()}} AC <span
                                class="value">{{shield.effectiveACBonus()}}</span>
                            Hardness: <span class="value">{{shield.effectiveHardness()}}</span>
                        </div>
                        <div class="fullsize-only newrow left-aligned" *ngIf="shield.traits.length">
                            <app-trait *ngFor="let trait of shield.traits; trackBy:trackers.trackByIndex;"
                                [name]="trait" [trait]="traitFromName(trait)">
                            </app-trait>
                        </div>
                        <app-tags [creature]="creature" [objectName]="'Shield'" [showTraits]=true [showFeats]=true
                            [showItems]=true [showActivities]=true [showConditions]=true [showEffects]=true
                            [specialNames]="specialShowOnNames(shield)">
                        </app-tags>
                        <div class="newrow">
                            <button class="center-aligned" *ngIf="!shield.raised"
                                (click)="onRaiseShield(true, shield)">Raise shield</button>
                            <button class="center-aligned fancy-button" *ngIf="shield.raised"
                                (click)="onRaiseShield(false, shield)">Lower
                                shield</button>
                            <button class="center-aligned"
                                *ngIf="shield.raised && shield.coverbonus && !shield.takingCover"
                                (click)="onSetCover(4, shield)">Take cover behind shield</button>
                            <button class="center-aligned fancy-button"
                                *ngIf="shield.raised && shield.coverbonus && shield.takingCover"
                                (click)="onSetCover(0, shield)">Leave cover behind shield</button>
                        </div>
                        <div class="list-item newrow">
                            <strong>HP</strong>
                            <div class="value">{{shield.currentHitPoints()}}</div>
                            <strong>Max HP</strong>
                            <div class="value">{{shield.effectiveMaxHP()}}</div>
                            <strong>BT</strong>
                            <div class="value">{{shield.effectiveBrokenThreshold()}}</div>
                        </div>
                        <div class="list-item newrow">
                            <ng-template #DamageSliderTemplate>
                                <div class="slider-container" [style.--name]="'\'Damage: ' + shieldDamage + '\''"
                                    style="min-width:25vw; max-width:100%;">
                                    <input class="slider" type="range" min="0" max="{{shield.effectiveMaxHP()}}"
                                        [(ngModel)]="shieldDamage">
                                </div>
                            </ng-template>
                            <span>
                                <button [disabled]="shieldDamage === 0"
                                    (click)="onChangeShieldHP(shield, shieldDamage)">Damage</button>
                            </span>
                            <span class="hlist center-aligned">
                                <input [ngbPopover]="DamageSliderTemplate" #DamagePopover="ngbPopover" triggers="manual"
                                    (focus)="DamagePopover.open()" class="number3" type="number"
                                    [(ngModel)]="shieldDamage" maxLength="3" min="0" max="{{shield.effectiveMaxHP()}}"
                                    (keypress)="positiveNumbersOnly($event)">
                            </span>
                            <span class="right-aligned">
                                <button [disabled]="shieldDamage === 0"
                                    (click)="onChangeShieldHP(shield, -shieldDamage)">Repair</button>
                            </span>
                        </div>
                        <div class="fullsize-only lower newrow left-aligned"
                            *ngFor="let talisman of shield.talismans; let index = index; trackBy:trackers.trackByIndex;">
                            <ng-template #TalismanTemplate>
                                <div class="list-item left-aligned lower"
                                    *ngFor="let cord of shield.talismanCords; trackBy:trackers.trackByIndex;">
                                    <header class="spellHeader">{{cord.name}}</header>
                                    <p>When you activate a talisman threaded through a cord with the same magic
                                        school trait that's also the cord's level or lower, attempt a <app-quickdice
                                            [diceString]="'1d20'"> </app-quickdice> DC 16 flat check. On a success,
                                        that talisman is not consumed and can be used again.
                                    </p>
                                    <ng-container
                                        *ngFor="let talismanCordIndex of [0, 1, 2]; trackBy:trackers.trackByIndex;">
                                        <div class="newrow"
                                            *ngIf="cord.isTalismanCord > talismanCordIndex && cord.data[talismanCordIndex]">
                                            <strong>{{cord.data[talismanCordIndex].name}}</strong>
                                            {{cord.data[talismanCordIndex].value}}
                                        </div>
                                    </ng-container>
                                </div>
                                <header class="spellHeader">{{talisman.name}}</header>
                                <button class="newrow left-aligned" (click)="onTalismanUse(shield, talisman, index)">
                                    <span>
                                        Activate
                                        <app-actionIcons *ngIf="talisman.actions" [actionString]="talisman.actions">
                                        </app-actionIcons>
                                        {{(talisman.activationType) ? talisman.activationType : ""}}
                                    </span>
                                </button>
                                <button class="newrow left-aligned"
                                    (click)="onTalismanUse(shield, talisman, index, true)"
                                    *ngIf="hasMatchingTalismanCord(shield, talisman)">
                                    <span>
                                        Activate and preserve with talisman cord
                                        <app-actionIcons *ngIf="talisman.actions" [actionString]="talisman.actions">
                                        </app-actionIcons>
                                        {{(talisman.activationType) ? talisman.activationType : ""}}
                                    </span>
                                </button>
                                <app-item [creature]="creature" [item]=talisman [allowActivate]=true [isSubItem]=true>
                                </app-item>
                            </ng-template>
                            <button [ngbPopover]="TalismanTemplate" triggers="click">
                                <span>
                                    {{talisman.name}}
                                    {{hasMatchingTalismanCord(shield, talisman) ? "(matching talisman cord attached)" :
                                    ""}}
                                </span>
                            </button>
                        </div>
                    </div>
                </ng-container>
                <header class="sectionHeader fullsize-only box-header" *ngIf="!stillLoading">Saving Throws</header>
                <app-tags [creature]="creature" [objectName]="'Saving Throws'" [showTraits]=true [showFeats]=true
                    [showItems]=true [showActivities]=true [showConditions]=true [showEffects]=true
                    [specialNames]="specialShowOnNames(undefined, true)"></app-tags>
                <ng-container *ngFor="let skill of skillsOfType('Save'); trackBy:trackers.trackByIndex;">
                    <app-skill [creature]="creature" [skill]=skill></app-skill>
                </ng-container>
                <ng-container *ngIf="!stillLoading">
                    <header class="sectionHeader fullsize-only box-header">Armor Proficiencies</header>
                    <ng-container
                        *ngFor="let skill of skillsOfType('Armor Proficiency'); trackBy:trackers.trackByIndex;">
                        <app-skill class="fullsize-only" [creature]="creature" [skill]=skill [showValue]=false>
                        </app-skill>
                    </ng-container>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="creature === 'Familiar'">
                <header class="sectionHeader fullsize-only box-header" *ngIf="!stillLoading">Saving Throws</header>
                <ng-container *ngFor="let skill of skillsOfType('Save'); trackBy:trackers.trackByIndex;">
                    <app-skill [creature]="creature" [skill]=skill></app-skill>
                </ng-container>
                <app-tags [creature]="creature" [objectName]="'Saving Throws'" [showTraits]=true [showFeats]=true
                    [showItems]=true [showActivities]=true [showConditions]=true [showEffects]=true></app-tags>
            </ng-container>
        </ng-container>
    </ng-container>
</div>
