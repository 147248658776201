<!-- eslint-disable @angular-eslint/template/cyclomatic-complexity -->
<cite class="trait"
    [ngClass]="{'trait-rare': trait.name === 'Rare', 'trait-uncommon': trait.name === 'Uncommon', 'extra-trait': extraDescription}">
    <ng-template #TraitContent>
        <div class="newrow left-aligned" *ngIf="trait.sourceBook">
            <strong>Source</strong>
            <i>{{trait.sourceBook}}</i>
        </div>
        <app-description [text]="split" *ngFor="let split of trait.desc.split('\n\n'); trackBy:trackers.trackByIndex;">
        </app-description>
        <div class="newrow left-aligned" *ngIf="extraDescription">
            {{extraDescription}}
        </div>
        <ng-container *ngIf="trait.objectEffects && trait.objectEffects.length && item">
            <app-description [text]="split"
                *ngFor="let split of trait.effectDesc.split('\n\n'); trackBy:trackers.trackByIndex;">
            </app-description>
        </ng-container>
    </ng-template>
    <span [ngbPopover]="TraitContent" triggers="hover">
        {{extraDescription ? "+ " : ""}}{{name || trait.name}}
    </span>
    <ng-container *ngIf="trait.objectEffects && trait.objectEffects.length && item">
        <ng-container *ngFor="let activation of objectTraitActivations(); trackBy:trackers.trackByIndex;">
            <input type="checkbox" [(ngModel)]="activation.active" (ngModelChange)="onActivateEffect()"
                [ngbTooltip]="'Activate any effects of this trait.'" triggers="hover">
            <input type="checkbox" [(ngModel)]="activation.active2" (ngModelChange)="onActivateEffect()"
                *ngIf="trait.extraActivations > 0" [ngbTooltip]="'Activate the second level of this trait\'s effects.'"
                triggers="hover">
            <input type="checkbox" [(ngModel)]="activation.active3" (ngModelChange)="onActivateEffect()"
                *ngIf="trait.extraActivations > 1" [ngbTooltip]="'Activate the third level of this trait\'s effects.'"
                triggers="hover">
        </ng-container>
    </ng-container>
</cite>
