<!-- eslint-disable @angular-eslint/template/cyclomatic-complexity -->
<!--Changing Material-->
<div class="list-item" *ngIf="materialOptionApplies">
    <div class="newrow">
        <strong>Material</strong>
        <select [(ngModel)]="newMaterial[0]" (ngModelChange)="onSelectMaterial()">
            <ng-container
                *ngFor="let material of initialMaterials(); let initialRuneIndex = index; trackBy:trackers.trackByIndex;">
                <option [ngValue]="material" *ngIf="initialRuneIndex === 0" [selected]=!item.material[0]>
                    <!--Blank option to deselect-->
                </option>
                <optgroup *ngIf="initialRuneIndex === 1" label="Current material">
                    <option [ngValue]="material">
                        {{material.material.name}}
                    </option>
                </optgroup>
            </ng-container>
            <ng-container *ngIf="availableMaterials$ | async as availableMaterials">
                <optgroup label="Available materials" *ngIf="availableMaterials.length">
                    <option *ngFor="let material of availableMaterials; trackBy:trackers.trackByIndex;"
                        [ngValue]="material" [disabled]="material.disabled"
                        title="{{materialTitle(material.material)}}">
                        {{material.material.name}}
                    </option>
                </optgroup>
            </ng-container>
        </select>
    </div>
    <ng-container *ngIf="craftingStation">
        <ng-container *ngFor="let material of item.material; trackBy:trackers.trackByIndex;">
            <div class="newrow" *ngIf="material.craftRequirement">
                <span>
                    <strong>Craft Requirement&nbsp;</strong>
                    <span>{{material.craftRequirement}}</span>
                </span>
            </div>
        </ng-container>
    </ng-container>
</div>
