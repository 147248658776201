<!-- eslint-disable @angular-eslint/template/cyclomatic-complexity -->
<!--Changing Weapon Runes-->
<div class="list-item" *ngIf="isRuneItem() && (item.moddable || customItemStore)">
    <ng-container *ngIf="runeItemType() as runeItemType">
        <ng-container *ngIf="previousValues() as previousValues">
            <!--Potency rune-->
            <div class="newrow">
                <strong>Potency rune</strong>
                <select [(ngModel)]="item.potencyRune"
                    (ngModelChange)="onSelectPotencyRune(previousValues.potency, runeItemType)">
                    <option
                        *ngFor="let potencySet of availablePotencyRunes(runeItemType); trackBy:trackers.trackByIndex;"
                        [ngValue]="potencySet.potency" [disabled]="potencySet.disabled"
                        title="{{runeTitle(potencySet.rune)}}">
                        {{item.potencyTitle(potencySet.potency)}}</option>
                </select>
            </div>
            <!--Resilient/Striking rune-->
            <div class="newrow" *ngIf="item.potencyRune > 0">
                <strong *ngIf="runeItemType.armor">Resilient rune</strong>
                <strong *ngIf="runeItemType.weapon">Striking rune</strong>
                <select [(ngModel)]="item.secondaryRune"
                    (ngModelChange)="onSelectSecondaryRune(previousValues.secondary, runeItemType)">
                    <option
                        *ngFor="let secondarySet of availableSecondaryRunes(runeItemType); trackBy:trackers.trackByIndex;"
                        [ngValue]="secondarySet.secondary" [disabled]="secondarySet.disabled"
                        title="{{runeTitle(secondarySet.rune)}}">
                        {{item.secondaryRuneTitleFunction(secondarySet.secondary)}}</option>
                </select>
            </div>
        </ng-container>
        <!--Property runes-->
        <ng-container *ngIf="item.potencyRune > 0">
            <div class="newrow" *ngFor="let index of propertyRunesSlots(); trackBy:trackers.trackByIndex;">
                <strong *ngIf="index === 0">Property runes</strong>
                <span *ngIf="index !== 0">&nbsp;</span>
                <select [(ngModel)]="newPropertyRune[index]" (ngModelChange)="onSelectPropertyRune(index, runeItemType)"
                    [disabled]="!item.propertyRunes[index-1] && index > 0">
                    <ng-container
                        *ngFor="let rune of initialPropertyRunes(index); let initialRuneIndex = index; trackBy:trackers.trackByIndex;">
                        <option [ngValue]="rune" *ngIf="initialRuneIndex === 0" [selected]=!item.propertyRunes[index]>
                            <!--Blank option to deselect-->
                        </option>
                        <optgroup *ngIf="initialRuneIndex === 1" label="Current rune">
                            <option [ngValue]="rune">
                                {{rune.rune?.name}}
                            </option>
                        </optgroup>
                    </ng-container>
                    <ng-container *ngFor="let inv of inventoriesOrCleanItems(); trackBy:trackers.trackByIndex;">
                        <ng-container
                            *ngIf="availablePropertyRunes(index, inv, runeItemType) as availablePropertyRunes">
                            <optgroup label="{{itemStore ? 'Available runes' : inventoryName(inv)}}"
                                *ngIf="availablePropertyRunes.length">
                                <option *ngFor="let runeSet of availablePropertyRunes; trackBy:trackers.trackByIndex;"
                                    [ngValue]="runeSet" [disabled]="runeSet.disabled"
                                    title="{{runeTitle(runeSet.rune)}}">
                                    {{(runeSet.rune?.amount || 0) > 1 ? runeSet.rune?.amount : ""}}
                                    {{runeSet.rune?.name}}
                                    {{runeCooldownText(runeSet.rune)}}
                                </option>
                            </optgroup>
                        </ng-container>
                    </ng-container>
                </select>
            </div>
        </ng-container>
    </ng-container>
</div>
