<!-- eslint-disable @angular-eslint/template/cyclomatic-complexity -->
<ng-container *ngFor="let phrase of phrases(); trackBy:trackers.trackByIndex;">
    <ng-container [ngSwitch]="phrase">
        <span class="actionIcon action1A" *ngSwitchCase="'1A'"></span>
        <span class="actionIcon action2A" *ngSwitchCase="'2A'"></span>
        <span class="actionIcon action3A" *ngSwitchCase="'3A'"></span>
        <span class="actionIcon actionFree" *ngSwitchCase="'Free'"></span>
        <span class="actionIcon actionReaction" *ngSwitchCase="'Reaction'"></span>
        <span class="lower" *ngSwitchDefault [innerHtml]="' '+phrase+' '"></span>
    </ng-container>
</ng-container>
