import { Defaults } from 'src/libs/shared/definitions/defaults';

export class Settings {
    public abilitiesMinimized = false;
    public accent = Defaults.colorAccent;
    public activitiesMinimized = false;
    public activitiesTileMode = true;
    public applyMessagesAutomatically = false;
    public archetypeFeats = true;
    public attacksMinimized = false;
    public autoCloseChoices = false;
    public characterMinimized = false;
    public characterTileMode = true;
    public craftingTileMode = true;
    public checkMessagesAutomatically = false;
    public companionMinimized = false;
    public conditionsTileMode = true;
    public darkmode = true;
    public defenseMinimized = false;
    public effectsMinimized = false;
    public familiarMinimized = false;
    public foundryVTTSendRolls = false;
    public foundryVTTUrl = '';
    public foundryVTTRollDirectly = false;
    public foundryVTTTimeout = Defaults.foundryMessageTTL;
    public generalMinimized = false;
    public healthMinimized = false;
    public hiddenFeats = false;
    public higherLevelFeats = true;
    public hintsShowMoreInformation = true;
    public itemsTileMode = true;
    public inventoryMinimized = false;
    public inventoryTileMode = true;
    public lowerLevelFeats = true;
    public manualMode = false;
    public noFriendlyCasterConditions = false;
    public noHostileCasterConditions = false;
    public sendTurnEndMessage = false;
    public sendTurnStartMessage = false;
    public showHeightenedSpells = false;
    public showOtherOptions = true;
    public showSkillActivities = true;
    public skillsMinimized = false;
    public skillsTileMode = true;
    public spellLibraryTileMode = true;
    public spellbookMinimized = false;
    public spellbookTileMode = true;
    public spelllibraryMinimized = false;
    public spellsMinimized = false;
    public spellsTileMode = true;
    public timeMinimized = false;
    public unavailableFeats = true;
}
