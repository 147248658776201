<!-- eslint-disable @angular-eslint/template/cyclomatic-complexity -->
<ng-container *ngIf="calculatedSkill() as calculatedSkill">
    <div class="list-item" [ngClass]="{'fullsize-only':(calculatedSkill.value.result === 0)}"
        *ngIf="minimized ? (calculatedSkill.value.result !== 0) : !(calculatedSkill.value.result === 0 && creature === 'Companion')">
        <div class="newrow">
            <ng-template #AbilityPopoverContent>
                <strong>Ability</strong> {{calculatedSkill.ability}}
            </ng-template>
            <ng-template #EffectsPopoverContent>
                <div class="fullsize-only newrow">
                    <app-objectEffects [creature]="creature" [objectName]="skill.name"></app-objectEffects>
                </div>
            </ng-template>
            <span class="hlist">
                <span class="hlist fullsize-only">
                    <input id="{{skill.name}}ShowNotes" class="invisible" type="checkbox"
                        [(ngModel)]="skillNotes(skill).showNotes">
                    <label for="{{skill.name}}ShowNotes" [ngbTooltip]="'Edit notes'">
                        <i class='bi-pencil-square'></i>
                    </label>
                    <ng-container *ngIf="(skill.name.includes(' DC') ? isDC : !isDC) && showValue">
                        <span [ngbPopover]="EffectsPopoverContent" #EffectsPopover="ngbPopover" triggers="click">
                            <i [ngbTooltip]="!EffectsPopover.isOpen() ? 'Edit effects' : ''"
                                class='bi-lightning-charge'></i>
                        </span>
                    </ng-container>
                </span>
                <strong
                    [ngbPopover]="calculatedSkill.ability ? AbilityPopoverContent : null">{{displayName(skill)}}</strong>
            </span>
            <app-proficiency-form style="flex-grow:0" [skillLevel]="skillProficiencyLevel()">
            </app-proficiency-form>
            <span *ngIf="showValue">
                <app-quickdice [creature]="creature" *ngIf="!isDC" [diceNum]="1" [diceSize]="20"
                    [bonus]="calculatedSkill.value.result" [type]="'('+displayName(skill)+')'">
                </app-quickdice>
                <div class="value" [ngbPopover]="((isDC) ? 'DC basis: 10\n' : '')+calculatedSkill.value.explain"
                    [ngClass]="{'penalty':calculatedSkill.penalties, 'bonus':calculatedSkill.bonuses, 'absolute':calculatedSkill.absolutes.length}">
                    {{calculatedSkill.value.result + ((isDC) ? 10 : 0)}}
                </div>
            </span>
        </div>
        <app-tags *ngIf="showValue" [creature]="creature" [objectName]=skill.name [showTraits]=true [showFeats]=true
            [showItems]=true [showActivities]=true [showConditions]=true
            [specialEffects]="calculatedSkill.absolutes.concat(calculatedSkill.relatives)">
        </app-tags>
        <div class="fullwidth" [ngbCollapse]="!skillNotes.showNotes" *ngIf="skillNotes(skill) as skillNotes">
            <div class="fullsize-only newrow">
                <span>
                    <strong>Notes</strong>
                    <input id="{{skill.name}}Notes" type="textarea" class="fullwidth" [(ngModel)]="skillNotes.notes">
                </span>
            </div>
        </div>
        <div class="fullwidth" *ngIf="relatedActivityGains.length"
            [ngClass]="{'icon-list':isTileMode, 'vlist':!isTileMode}">
            <ng-container
                *ngFor="let activityParameters of relatedActivityParameters(); let activityIndex = index; trackBy:trackers.trackByIndex;">
                <ng-template #ActivityTitleTemplate>
                    <span *ngIf="!isTileMode">
                        <i class="value bi-patch-plus bonus" *ngIf="!activityParameters.activity.isHostile()"
                            [ngbTooltip]="'Beneficial activity'"></i>
                        <i class="value bi-patch-minus-fill penalty" *ngIf="activityParameters.activity.isHostile()"
                            [ngbTooltip]="'Hostile activity'"></i>
                    </span>
                    <span *ngIf="activityParameters.activity.name === 'Fused Stance'">{{fuseStanceName()}}</span>
                    <span
                        *ngIf="activityParameters.activity.name !== 'Fused Stance'">{{activityParameters.activity.name}}</span>
                    <app-actionIcons *ngIf="activityParameters.activity.actions"
                        [actionString]="activityParameters.activity.actions">
                    </app-actionIcons>
                    {{activityParameters.activity.activationType || ""}}
                </ng-template>
                <ng-template #ActivityTemplate>
                    <header class="spellHeader" *ngIf="isTileMode">
                        <ng-container *ngTemplateOutlet="ActivityTitleTemplate"></ng-container>
                    </header>
                    <app-activity [creature]="creature" [activity]="activityParameters.activity"
                        [gain]="activityParameters.gain" [allowActivate]="true">
                    </app-activity>
                </ng-template>
                <ng-container *ngIf="!isTileMode">
                    <div class="list-item"
                        *ngFor="let activityID of [skill.name+activityIndex]; trackBy:trackers.trackByIndex;">
                        <button class="newrow left-aligned sublist-toggle"
                            [ngClass]="{'fancy-button':activityParameters.gain.active, 'inactive-button':activityParameters.cannotActivate}"
                            (click)="toggleShownAction(activityID)">
                            <ng-container *ngTemplateOutlet="ActivityTitleTemplate"></ng-container>
                        </button>
                        <div class="list-item sublist lower"
                            [ngClass]="{'fancy-list':activityParameters.gain.active, 'inactive-list':activityParameters.cannotActivate}"
                            *ngIf="shownAction()===activityID">
                            <ng-container *ngTemplateOutlet="ActivityTemplate"></ng-container>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="isTileMode">
                    <button [stickyPopover]="ActivityTemplate" triggers="click"
                        [ngClass]="{'fancy-button':activityParameters.gain.active, 'inactive-button':activityParameters.cannotActivate, 'penalty':!activityParameters.cannotActivate && activityParameters.activity.isHostile, 'bonus':!activityParameters.cannotActivate && !activityParameters.activity.isHostile}">
                        <app-gridIcon
                            [ngClass]="{'fancy-button':activityParameters.gain.active, 'inactive-button':activityParameters.cannotActivate, 'penalty':!activityParameters.cannotActivate && activityParameters.activity.isHostile, 'bonus':!activityParameters.cannotActivate && !activityParameters.activity.isHostile}"
                            [ngbTooltip]="ActivityTitleTemplate"
                            [title]="activityParameters.activity.name === 'Fused Stance' ? fuseStanceName() : activityParameters.activity.name"
                            [activity]="activityParameters.activity" [activityGain]="activityParameters.gain"
                            [updateId]="activityParameters.gain.id">
                        </app-gridIcon>
                    </button>
                </ng-container>
            </ng-container>
        </div>
    </div>
</ng-container>
