<!-- eslint-disable @angular-eslint/template/cyclomatic-complexity -->
<ng-container *ngIf="iconValue() as iconValueResult">
    <ng-container *ngIf="iconValueResult.includes('<'); then InnerHtmlDivTemplate; else TextDivTemplate">
    </ng-container>
    <ng-template #InnerHtmlDivTemplate>
        <span class="iconValue disable-text-selection" [ngStyle]="{'color': effect ? 'inherit' : ''}"
            [innerHtml]="iconValueResult"></span>
    </ng-template>
    <ng-template #TextDivTemplate>
        <span class="iconValue disable-text-selection"
            [ngStyle]="{'color': effect ? 'inherit' : ''}">{{iconValueResult}}</span>
    </ng-template>
</ng-container>
<ng-container *ngIf="iconDetail() as iconDetailResult">
    <ng-container *ngIf="iconDetailResult.includes('<'); then InnerHtmlDivTemplate; else TextDivTemplate">
    </ng-container>
    <ng-template #InnerHtmlDivTemplate>
        <span class="iconDetail disable-text-selection" [innerHtml]="iconDetailResult"></span>
    </ng-template>
    <ng-template #TextDivTemplate>
        <span class="iconDetail disable-text-selection">{{iconDetailResult}}</span>
    </ng-template>
</ng-container>
<ng-container *ngIf="iconSuperTitle() as iconSuperTitleResult">
    <span class="iconSuperTitle disable-text-selection" *ngIf="iconSuperTitleResult">
        <ng-container *ngIf="iconSuperTitleResult.includes('<'); then InnerHtmlDivTemplate; else TextDivTemplate">
        </ng-container>
        <ng-template #InnerHtmlDivTemplate>
            <div [innerHtml]="iconSuperTitleResult"></div>
        </ng-template>
        <ng-template #TextDivTemplate>
            <div>{{iconSuperTitleResult}}</div>
        </ng-template>
    </span>
</ng-container>
<ng-container *ngIf="iconTitle() as iconTitleResult">
    <span class="iconTitle disable-text-selection" *ngIf="iconTitleResult">
        <ng-container *ngIf="iconTitleResult.includes('<'); then InnerHtmlDivTemplate; else TextDivTemplate">
        </ng-container>
        <ng-template #InnerHtmlDivTemplate>
            <div [innerHtml]="iconTitleResult"></div>
        </ng-template>
        <ng-template #TextDivTemplate>
            <div>{{iconTitleResult}}</div>
        </ng-template>
    </span>
</ng-container>
<ng-container *ngIf="iconSubTitle() as iconSubTitleResult">
    <ng-container
        *ngIf="iconSubTitleResult.includes('<') || iconSubTitleResult.includes('|'); then InnerHtmlDivTemplate; else TextDivTemplate">
    </ng-container>
    <ng-template #InnerHtmlDivTemplate>
        <span class="iconSubTitle disable-text-selection" *ngIf="!iconSubTitleResult.includes('actionIcons|')"
            [innerHtml]="iconSubTitleResult"></span>
        <span class="iconSubTitle disable-text-selection" *ngIf="iconSubTitleResult.includes('actionIcons|')">
            <app-actionIcons [actionString]="iconSubTitleResult.split('|')[1]"></app-actionIcons>
            <span *ngIf="iconSubTitleResult.split('|').length > 1"
                [innerHtml]="iconSubTitleResult.split('|')[2]"></span>
        </span>
    </ng-template>
    <ng-template #TextDivTemplate>
        <span class="iconSubTitle disable-text-selection">
            {{iconSubTitleResult}}
        </span>
    </ng-template>
</ng-container>
<ng-container *ngIf="(condition?.maxDuration || 0) > 0 || (effect?.duration || 0 > 0)">
    <ng-container *ngFor="let overlay of durationOverlays(); trackBy:trackers.trackByIndex;">
        <div class="gridicon-time-overlay" [style.--offset]="overlay.offset" [style.--percentage]="overlay.percentage"
            [style.--over50]="overlay.over50" [ngClass]="{'transition5':!overlay.over50}"></div>
    </ng-container>
</ng-container>
